(function ($) {
    $.fn.ashFilter = function (options) {
        var defaults = {
            event: 'click',
            trigger: $('.ashFilterTrigger').find('button'), //elem user clicks to filter items
            filterItem: $('.ashFilter'), //which items will be filtered
            triggerAll: 'all', //element which turns off the filter and shows all items
            addSelected: null, //class to add to selected trigger
            callback: null, //function to execute after items are filtered
            callbackOptions: null,
            fadeInDur: 300,
            fadeOutDur: 300,
            offOption: false, //option to hide filterItem when unchecked
            triggerGroup: false, //if trigger is part of a group of inputs
            preventDefault: false
        };
        
        var settings = $.extend({}, defaults, options);

        return this.each(function () {
            var s = settings,
                filterTag,
                filterTagObj,
                groupTag,
                groupItems, //for more than one filter group in a single page
                obj;

            s.trigger.each(function () {
                var thisTrigger = $(this),
                    groupChecked = false; //create var to see if group of inputs, when a group is used, has an input checked

                function setupFilter(obj) {
                    //add selcted class to current trigger only
                    if (s.addSelected) {
                        s.trigger.removeClass(s.addSelected);
                        obj.addClass(s.addSelected);
                    }
                    //label that is being filtered to show
                    if ((obj).is('select') === true) {
                        filterTag = obj.find('option:selected').attr('data-ash-filter-trigger');
                    } else {
                        filterTag = obj.attr('data-ash-filter-trigger');
                    }
                    groupTag = obj.attr('data-match-group');

                    //make JQ object out of label
                    filterTagObj = $('[data-ash-filter*="' + filterTag + '"]');
                    groupItems = groupTag ? $('[data-match-group-items*="' + groupTag + '"]') : groupTag;  //tag on filterTrigger to identify filter groups and group items

                    if (s.triggerGroup) {
                        s.trigger.each(function () {
                            if ($(this).prop('checked')) {
                                groupChecked = true;
                            }
                        });
                    }
                    //if turning off the filter and displaying all items
                    if (filterTag === s.triggerAll) {
                        filter(s.filterItem, null, true, false);
                    } else if (s.offOption && !(thisTrigger.prop('checked'))) {
                        //if trigger is part of a group of inputs
                        if (s.triggerGroup) {
                            //if all grouped inputs are not checked, then hide filterItem
                            if (!groupChecked) {
                                filter(null, s.filterItem, false, true);
                            }
                        } else {
                            filter(null, s.filterItem, false, true);
                        }
                    }
                    else {
                        if (groupItems) {
                            filter(filterTagObj, groupItems.find(s.filterItem).not(filterTagObj), false, false);
                        }else{
                            filter(filterTagObj, (s.filterItem).not(filterTagObj), false, false);
                        }
                    }
                }

                $(this).on(s.event, function (e) {
                    setupFilter($(this));
                    //set groupChecked to false, setupFilter will run through each input to see if one is checked
                    groupChecked = false;

                    if (s.preventDefault === true) {
                        e.preventDefault();
                    }
                });

                $(document).ready(function () {
                    if (thisTrigger.prop('checked')) {
                        setupFilter(thisTrigger);
                    }
                });

            });

            function filter(onItems, offItems, showAll, hideAll) {
                 
                if (!showAll) {
                    offItems.fadeOut({
                        duration: s.fadeOutDur
                    });
                }
                

                if (hideAll) {
                    offItems.fadeOut({
                        duration: s.fadeOutDur
                    });
                }
                else {
                    onItems.fadeIn({
                        duration: s.fadeInDur
                    });
                }

                if (s.callback) {
                    s.callback(s.trigger, s.callbackOptions);
                }

            }

        });
    };
})(jQuery);
