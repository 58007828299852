//main off canvas nav
function offCanvasNavInit() {
	var navbarToggle = $('.navbar-toggle'),
		slideContent = $('.slider-cont'),
		mainContent = $('.main-content'),
		dropdownToggle = $(".dropdown-toggle ins"),
		body = $('body'),
		mask = $('<div/>', { 'class': 'mask' }),
		mobileHeader = $('.mobile-header');

	if (!body.hasClass('offCanvas')) {
		//insert the mask into the DOM
		slideContent.prepend(mask);

		//bind click event to mask
		mask.on('click', function () {
			if (body.hasClass('active')) {
				body.removeClass('active');

				mobileHeader.css({
					'top': 'auto'
				});
			}
		});

		//bind click event to menu button
		navbarToggle.on('click', function () {
			var offset;
			body.toggleClass('active');
			if (body.hasClass('active')) {
				//move header down. position fixed and use of transform requires a top position
				offset = $('header').offset().top;
				mobileHeader.css({
					'top': offset
				});
			}
		});
		if (Modernizr.mq("screen and (max-width: 767px)")) {
			dropdownToggle.on("click", function () {
				$(this).parent().toggleClass("dropdown-selected");
			});
		}

		body.addClass('offCanvas');
	}
}