var bulletinInit = function () {
	var obj = document.querySelector('.bulletin');
	var loadedClass = 'loaded';
	var movedClass = 'moved';
	var scrolledClass = 'bulletinScrolled';
	var closer = document.querySelector('.close-bulletin');
	var mainContainer = document.querySelector('.main-container');
	var bodyInner = document.querySelector('.body-inner');
	var header = document.querySelector('header.mainHeader') || document.querySelectorAll('header')[0];

	//checking to make sure all vars exist so whole bundle will not throw an error
	if (!obj || !closer || !mainContainer || !bodyInner || !header) { return };

	function moveBulletin() {
		if (!obj.classList.contains(movedClass) && Modernizr.mq("(max-width: 767px)")) {
			//move bulletin to main container if on small screensizes
			mainContainer.insertBefore(obj, mainContainer.childNodes[0]);
			obj.classList.add(movedClass);

		} else if (obj.classList.contains(movedClass) && !Modernizr.mq("(max-width: 767px)")) {
			//move bulletin back bodyInner if the screen size goes above sm mq and if it has moved class
			bodyInner.insertBefore(obj, bodyInner.childNodes[0])
			obj.classList.remove(movedClass);
		}
	};

	//if bulletin exists wraps all functions
	if (obj && !obj.classList.contains(loadedClass)) {

		moveBulletin();
		obj.classList.add(loadedClass);


		if (obj.classList.contains(loadedClass)) {
			bodyInner.classList.add('hasBulletin');
			mainContainer.style.paddingTop = '0px';
			header.style.position = 'relative';
		}
		//makes the header stick to the top after scrolling when bulletin is open
		function bulletinScroll() {
			var objHeight = obj.offsetHeight;
			var headerHeight = header.offsetHeight;
			//pageYOffest is part of the window api and has better cross broswer support than scrollY
			//see mdn link: https://developer.mozilla.org/en-US/docs/Web/API/Window/pageYOffset
			var currentPosition = window.pageYOffset;

			//added or check for moved class since the header needs to always be position fixed on mobile
			if ((objHeight <= currentPosition) || obj.classList.contains(movedClass)) {
				//resticks header on scroll down
				header.style.position = 'fixed';
				mainContainer.style.paddingTop = headerHeight + 'px';
				header.classList.add(scrolledClass);

			} else {
				header.style.position = 'relative';
				header.classList.remove(scrolledClass);
				mainContainer.style.paddingTop = '0px';
			}
		}

		window.addEventListener('scroll', function () {
			bulletinScroll();
		});
		//had to remove setTimeout because it was causing bulletin to occasionally not show up
		['orientation', 'resize'].forEach(function (event) {
			window.addEventListener(event, function () {
				moveBulletin();
				bulletinScroll();
			});
		});

		// closes bulletin by removing loaded class on click
		closer.addEventListener('click', function (e) {
			e.preventDefault();

			obj.classList.remove(loadedClass);
		});
	}//if bulletin exists wraps all functions
};