//base partial

//get parameter for url
$.urlParam = function (name) {
	var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
	//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href); //original version. fixing regex to fix hint error
	if (results === null) {
		return null;
	} else {
		return results[1] || 0;
	}
};

//OBJECT UTILITY FUNCTIONS
var ObjFuncs = function () {
	var obj = this;

	obj.copyHtml = function (modObj) {
		obj.htmlStruc = modObj.html();
	};
	obj.pasteHtml = function () {
		return obj.htmlStruc;
	};
};

//SETUP ASHMODAL
function setupAshModal(el, cbOnClose, cbOnClick) {
	if (el.length) {
		//if element has data-ash-ashmodalformat attribute
		var obj = el.attr('data-ash-ashmodalformat') ? el : $('.modalOpen');

		obj.each(function () {
			var obj = $(this),
				modalMatch = $('.ashModalCont[data-ash-ashmodalmatch="' + obj.attr('data-ash-ashmodalmatch') + '"]'),
				modalClass = obj.attr('data-ash-addmodalclass') ? obj.attr('data-ash-addmodalclass') : null;
			obj.ashModal({
				'theContent': modalMatch,
				'closeContent': '<span class="icon-closeExit" aria-hidden="true"></span>',
				'addModalClass': modalClass,
				'callbackAfterClick': cbOnClick,
				'callbackAfterClose': cbOnClose,
				'removePageScroll': true
			});
		});
	}
}

function runEqualCols() {
	if (!$('.no-flexbox .tabs')) {
		$('.tabs').equalColumnWidth();
	}
}

function setupJqueryDatepicker(opts) {
	//original date input
	var dateInput = $('input[type="date"]'),
		//datepicker object
		pickerInput = null,
		//boolean flag set to true if the form has class 'validateDate'
		validateDate = dateInput.closest('form').hasClass('validateDate');
	//flag for determining if the existing date input has a class attribute
	classAttr = false;
	dateInput.each(function () {
		var target = this;
		//this input will be the forward-facing datepicker
		var dispInput = '<input type="text" ';
		//this input will be hidden and contain the value that will be sent to the database
		//only display it when 'validateDate' class is present
		if (validateDate) {
			var parseInput = '<input type="hidden" ';
		}
		if (typeof ko !== 'undefined') {
			//if ko used, add any new values to data tag before processing
			target.setAttribute('data-ash-populate', target.value);
		}
		//if the date input has more attributes than just type="date"
		if (target.attributes.length > 1) {
			//loop through each attribute to transfer them to the new datepicker input
			$(target.attributes).each(function () {
				//exclude the type attribute (we don't want to carry that over to the new input)
				if (this.nodeName !== 'type') {
					if (this.nodeName === 'class') {
						classAttr = true;
						//transfer any existing classes and add the 'date-picker' class to the forward-facing input
						dispInput += 'class="' + this.value + ' date-picker" ';
					} else if (this.nodeName === 'name' && validateDate) {
						//add an identifiable id to the hidden input using the name attribute value
						//transfer the original name attribute value to the hidden input
						parseInput += 'id="' + this.value + 'Parse" name="' + this.value + '" ';
						//do not display a name value on the forward-facing input to prevent display input from submitting data
						//add a target-id attribute to relate the forward-facing input to its corresponding hidden input
						dispInput += 'data-ash-target-id="' + this.value + 'Parse" ';
					} else if (this.nodeName === 'value' && this.value !== '') {
						if (validateDate) {
							//set the hidden input value to the iso format
							parseInput += 'value="' + this.value + '" ';
							//set the forward-facing value to the standard format
							dispInput += 'value="' + $$ash.convertDate({ str: this.value, iso: false }) + '" ';
						} else {
							//set the hidden input value to the iso format
							parseInput += 'value="' + this.value + '" ';
							//set the forward-facing value to the iso format
							dispInput += 'value="' + this.value + '" ';
						}
					} else {
						//transfer any remaining attributes and their values
						dispInput += this.nodeName + '="' + this.value + '" ';
					}
				}
			});
			//if date input only has type attribute, be sure to add the 'date-picker' class to initialize jQuery UI Datepicker
		} else {
			dispInput += 'class="date-picker" ';
		}
		//if date input had additional attributes besides type="date", but no class attribute, add the 'date-picker' class to initialize jQuery UI Datepicker
		if (!classAttr) {
			dispInput += 'class="date-picker" ';
		}
		dispInput += '/>';
		if (validateDate) {
			parseInput += '/>';
			$(this).after(parseInput);
		}
		// 1. add 'date-cont' class to parent
		// 2. add svg span after the existing date input to hold the calendar icon
		// 3. add hidden input after the svg icon
		// 4. replace the existing date input with the new text input
		$(this).parent().addClass('date-cont').end().after('<span class="svg-date-picker"></span>').replaceWith(dispInput);
	});
	pickerInput = $('.date-picker');

	if ($.datepicker) { //check to see if datepicker is available TODO: find a way for datepicker to work in HMS
		if (validateDate) {
			pickerInput.datepicker({
				altField: '#' + $(this).attr('data-ash-target-id'),
				altFormat: 'yy-mm-dd',
				changeMonth: true,
				changeYear: true,
				yearRange: 'c-100:c+20',
				minDate: new Date(1899, 1 - 1, 1),
				onClose: function (date) {
					var obj = this;
					if (obj.hasAttribute('data-ash-match')) {
						$$ash.prepopulateDate(obj, date);
					}
					//if validate class, set hidden input
					if ($(obj).hasClass('validate')) {
						$$ash.setHiddenInput(obj);
					}
				}
			});
		} else {
			pickerInput.datepicker({
				dateFormat: opts.format || 'yy-mm-dd',
				changeMonth: true,
				changeYear: true,
				yearRange: 'c-100:c+20',
				minDate: new Date(1899, 1 - 1, 1),
				onClose: function (date) {
					var obj = this;
					if (obj.hasAttribute('data-ash-match')) {
						$$ash.prepopulateDate(obj, date);
					}
					//if validate class, set hidden input
					if ($(obj).hasClass('validate')) {
						$$ash.setHiddenInput(obj);
					}
				}
			});
		}
		if (typeof ko !== 'undefined') {  //if ko is used, move value from data tag to val
			pickerInput.each(function () {
				var obj = $(this),
					date = obj.attr('data-ash-populate');
				if (date) {
					obj.val(date);
					obj.removeAttr('data-ash-populate');
				}
			});
		}
	}
	$('.svg-date-picker').click(function () {
		$(this).prev().datepicker('show');
	});

	$(document).trigger('datePickerInitialized');

	$$ashVal.init();
}

$(document).ready(function () {
	//devices with touch and larger than 767 - ipad
	if (Modernizr.mq($$ash.mq('sm', 'min')) && Modernizr.touchevents) {
		$('.dropdown-toggle > a').on('click', function (e) {
			e.preventDefault();
		});
	}
	//accordion using gsap
	if ($('.accordion-header').length) {
		$('.accordion-header').each(function () {
			var obj = $(this),
				targetContent = $(this).next();

			obj.on('click', function (e) {
				if (targetContent.hasClass('expanded')) {
					TweenLite.to(targetContent, 0.3, { height: 0 });
					targetContent.removeClass('expanded');
				} else {
					targetContent.addClass('expanded');
					targetContent.css({ 'height': 'auto' });
					TweenLite.from(targetContent, 0.3, { height: 0 });
				}
			});
		});
	}
	$(this).trigger('$$ash:ready'); //custom event initially created for landing page connected widget. Fires custom event "$$ash:ready" on the document and guarantees that $$ash is defined.

	//if loader needs to run on document ready as the page is first loading
	if ($('.loaderInit').length) {
		var obj = $('.loaderInit');

		$$ash.addLoader(obj, 'append', 'center');
	}

	$(' .radio-img-cont').each(function () {
		var inp = $(this).find('input'),
			inpName = inp.attr('name'),
			otherInp = $('input[name="' + inpName + '"]').closest('.radio-img-cont').find('.radio-img-cont-img'), //need to turn off checked state on other radio options for this question
			img = $(this).find('.radio-img-cont-img'),
			isChecked;

		inp.on('change', function () {
			isChecked = inp.prop('checked');
			if (isChecked) {
				otherInp.removeClass('checked');
				img.addClass('checked');
			} else {
				img.removeClass('checked');
			}
		});
	});

	$('.radio-trigger').each(function () {
		var cont = $(this),
			contInputs = cont.find('input'),
			recCont = $('#' + cont.attr('data-radio-trigger-match')), //matching element that will receive selections base on conts radios
			recInputs = recCont.find('input');

		contInputs.each(function () {
			$(this).on('change', function () {
				//on input change, see if this input triggers a match input
				if ($(this).attr('data-radio-trigger')) {
					//check the matched input
					var match = recCont.find('#' + $(this).attr('data-radio-trigger')).prop('checked', true);
					//disable the non-matched inputs
					recInputs.not(match).attr('disabled', true);
				} else {
					//if the input doesn't have a match, turn off disabled inputs
					recInputs.attr('disabled', false);
				}
			});
		});
	});

	//COMPONENT NAV
	var compNavHolder = $('.component-nav-holder'), //holder for the mobile toggle nav
		compNav = $('ul.component-nav'); //main component-nav

	if (compNav) {
		$(window).on('resize.compNav orientationchange.compNav', function () {
			compNav.css('display', '');
		});
	}
	//open and close nav on mobile
	compNavHolder.on('click', function () {
		compNav.slideToggle();
	});
	//toggle selected (open) class on navHolder
	$('div.component-nav-holder').on('click', function () {
		$(this).toggleClass('selected');
	});
	//accordion holder
	var accordionHolder = $('.accordion-nav-holder'), //holder for the mobile toggle accordion
		accordion = $('ul.accordion'); //main accordion

	//open and close accordion on mobile
	accordionHolder.on('click', function () {
		accordion.slideToggle();
	});
	//main list items in component nav
	function accordionComponent() {
		$('ul.component-nav > li, ul.accordion > li').each(function () {
			var cont = $(this), //category item
				obj = $(this).children(':first-child').nextUntil('ul').addBack(), //set of selectors user can click
				list = cont.find('ul'), //sub nav
				subnavSelected = cont.find('.selected'), //current selected subnav item
				indicator = cont.find('.indicator'); //arrow indicator used to display when the menu is open

			obj.on('click', function () {
				//toggle subnav visibility
				if (list.length > 1) {
					list.slideToggle();
				}
				//toggle selected state
				function toggleSel() {
					cont.toggleClass('selected');
				}
				toggleSel();
			});
			//change ui for subnav items when clicked
			(list.children()).on('click', function () {
				subnavSelected.removeClass('selected');
				$(this).addClass('selected');
				subnavSelected = $(this);
			});
		});
	}
	accordionComponent();//call this for ASHCO accordion
	//set print functionality to any a tag with "print" in the class
	$('.trigger-print').on('click', function (event) {
		event.preventDefault();
		window.print();
	});

	if ($('.placeholder-watch').length) {
		$('.placeholder-watch').each(function () {
			var obj = $(this),
				objNext = obj.next(),
				text = obj.text();
			if (objNext.attr('placeholder')) {
				objNext.removeAttr('placeholder');
			}
		});
	}

	$('.readTranscript').on('click', function (e) {
		e.preventDefault();
		var transcript = $('#transcript'),
			headerOffset = (Modernizr.mq('screen and (max-width: 767px)')) ? 0 : $('header').height();
		transcript.trigger('click');
		$('html, body').animate({
			scrollTop: (transcript.offset().top - headerOffset)
		}, 2000);
	});

	$('ul.exp-data-gallery').each(function () {
		$(this).ashExpDataGallery();
	});

	//USER LISTS
	$('.user-list').on('click', '.remove', function (e) {
		e.preventDefault();
		$$ash.userList.removeUser($(this), '.user-list');
	});
	$.datePickerSetup = function (opts) {
		//Find out where to retrieve the asset
		var assetPrefixBase = (typeof window.$$assetPrefix === 'string') ? window.$$assetPrefix + '/Base/assets' : '/App_Themes/Base';
		//IF input type date not supported
		if ((!Modernizr.inputtypes.date)) {
			//JQUERY UI DATE PICKER
			if ($('input[type=date]').length) {
				if (typeof $.datepicker === 'undefined') {
					$$ash.ajax({
						url: assetPrefixBase + '/lib/jquery-ui-datepicker-1.11.1.min.js',
						async: false,
						dataType: "script"
					}).done(function () {
						setupJqueryDatepicker(opts);
					});
				} else {
					setupJqueryDatepicker(opts);
				}
			}

			//IF TOUCH IS SUPPORTED
		} else {
			if ($u('input[type=date]')) {
				for (var i = 0; i < $u('input[type=date]').length; i++) {
					var obj = $u('input[type=date]')[i];

					if (obj.className.indexOf('startDate') !== -1) {
						obj.addEventListener('blur', function () {
							$$ash.prepopulateDate(obj, null);
						}, false);
					}
				}
			}
		}
	};
	$.datePickerSetup({
		initialLoad: true
	});
	//EClasses, tab-template is the quiz container where the quiz will be displayed
	//trigger-quiz is the initial quiz button to "take the quiz"
	$('#tab-template').on('click', 'a.trigger-quiz', function (e) {
		//data attrs are on tab-template container, make obj = tab-template //trigr, jsonUrl, templateUrl, targetId, callback
		e.preventDefault();
		e.currentTarget.disabled = true;
		var obj = $(e.target).closest('#tab-template');
		if (!obj.hasClass('content-container')) {
			obj.addClass('content-container');
		}
		$$ash.getTemplateData(obj, obj.attr('data-json-url'), obj.attr('data-ash-template-url'), obj.attr('data-ash-target-id'), quizSubmit);
	});
	var quizSubmit = function () {
		$('#tab-template').one('submit', 'form.quiz', function (e) {
			e.preventDefault();
			var obj = $(this),
				ajaxData = obj.returnCheckedInputsAsObject();

			$$ash.ajaxFormSubmit($(this), {
				data: ajaxData,
				cbOpts: { ele: obj },
				callback: quizFeedback
			}); //be sure to pass the form element along with a callback function
		});
	};
	function quizFeedback(response, textStatus, xhr, cbOpts) { //ele = form.quiz  //response = json data
		var ele = cbOpts.ele,
			obj = ele.closest('#tab-template'), //getting tab template
			templateCont = $('#tab-template');

		response = $$ash.unstringJSON(response);

		if (response.quizPass === true) { //if quiz passes
			$$ash.removeLoader();
			ele.parent().html(response.eClassContent);  //load the congratulations page
			templateCont.removeClass('content-container');
		} else {  //if quiz fails or there is error
			$.ajax({
				url: obj.attr('data-ash-template-url'),
				type: 'GET',
				success: function (data) {
					var template = Handlebars.compile(data),
						html = template(response);

					$$ash.removeLoader();
					templateCont.html(html);
					$$ashVal.scrollForm($('#tab-template form.quiz'));
					quizSubmit();
				},
				error: function (xhr) {
					$$ash.ajaxFail(obj, undefined, xhr);
				}
			});
		}
	}

	//STYLED FILE UPLOAD
	if ($('.file-upload').length) {
		$('.file-upload').each(function () {
			var fileUploadObj = $(this),
				test = fileUploadObj.find('input[type="file"]');

			(function fileUpload() {
				var obj = test,
					ajaxUrl = obj.attr('data-ajax-url'),
					ajaxImg = obj.attr('data-ajax-image'),
					targetImage = $('#' + ajaxImg),
					fileNameText = fileUploadObj.find('.upload-status-filename');

				obj.ashFileUpload({
					"action": ajaxUrl,
					"targetImage": targetImage
				});

				obj.on('change', function () {
					var fileName = obj.val().split('/').pop().split('\\').pop();

					fileNameText.text(fileName);

					$(this).ashFileUpload({
						"action": ajaxUrl,
						"targetImage": targetImage
					});
				});
			})();
		});
	}

	if ($('.tabs').length) {
		var trigr = $('.tabs .tab.selected a'),
			jsonUrl = trigr.attr('data-json-url'),
			templateUrl = trigr.attr('data-ash-template-url'),
			targetId = trigr.attr('data-ash-target-id'),
			notTabsFilter = !$('.tabs').is('.tabsFilter'),
			tempID = $('#tab-template'),
			hasCont,
			callback;

		if (notTabsFilter) {  //if not using tabs via filter, generate template
			$$ash.getTemplateData(trigr, jsonUrl, templateUrl, targetId, callback);
		}

		$('.tabs .tab a').on('click', function (e) {
			e.preventDefault();
			trigr = $(this);
			jsonUrl = trigr.attr('data-json-url');
			templateUrl = trigr.attr('data-ash-template-url');
			targetId = trigr.attr('data-ash-target-id');
			hasCont = trigr.attr('data-ash-hascont');
			$('.error').remove();
			var trigrLi = trigr.parent(),
				allTabs = trigrLi.siblings(),
				tabCont = $('#' + targetId);
			tabCont.html('');
			allTabs.removeClass('selected'); //remove selected class
			if (hasCont === 'true') { //if tab has its own container
				tabCont.removeClass('content-container');
			} else {
				tabCont.addClass('content-container');
			}
			trigrLi.addClass('selected'); //add selected class on newly selected button


			if (notTabsFilter) {  //if not using tabs via filter, generate template
				$$ash.getTemplateData(trigr, jsonUrl, templateUrl, targetId, callback);
			}
		});
		//equal width tabs for no flexbox browsers
		var noFlexTab = $('.no-flexbox .tab');
		if (noFlexTab.length) {
			$(window).on('orientationchange resize', function () {
				if ($(window).width() < 768) {
					noFlexTab.css({ 'width': '50%' });
				} else {
					runEqualCols();
				}
			});
		}
	}
	//jump to content based on the css id added to an html element
	$.jumpToContent = function () {
		var toId = $.urlParam('jump'), //id where the url should take you
			target = $('#' + toId), //object in html to position browser
			stickyNav = $('.ash-sticky-nav'), //sticky nav object
			stickyNavHt = 0, //height of sticky navs
			offSet = 50;

		stickyNav.each(function () {  //add up sticky nav heights
			if (($(this).is('nav') && $(this).css('display') !== 'none') || ($(this).is('header') && $(this).css('opacity') !== 0)) {
				stickyNavHt += $(this).outerHeight();
				offSet = 10;
			}
		});

		//animate / scroll to place in the browser
		$('html, body').animate({
			scrollTop: target.offset().top - stickyNavHt - offSet
		}, 1000);
	};

	//DONUT INIT
	var donutChartHolder = $('.donut-chart');
	if (donutChartHolder.length) {
		if (!$$donutCallback) {
			var $$donutCallback = null;
		}
		donutChartHolder.each(function () {
			var obj = $(this),
				donutWidth = $(this).width();

			obj.height(donutWidth).progressGraphInit(donutWidth, $$donutCallback);
		});
	}
	// NOTE: Remove ^^^ once the integration for vvv is complete as a part of #87579 - JW
	// Initialize donut chart
	var donutChart = $u('.donutChart');
	if (donutChart) {
		donutChart.forEach(function (element) {
			$$ash.initializeDonutChart(element);
		});
	}

	//CENTER ELEMENT
	$.fn.center = function () {
		this.css("position", "fixed");
		this.css("top", ($(window).height() / 2) - (this.outerHeight() / 2));
		this.css("left", ($(window).width() / 2) - (this.outerWidth() / 2));
		return this;
	};

	//SEND PROGRESS INDICATOR MESSAGE
	$.fn.progressIndicator = function (options) {
		var defaults = {
			'msg': 'message', //message to show
			'attachMsg': 'body', //location to attach message
			'triggerType': 'checkbox', //type of trigger sending message
			'buttonTrigger': false,  //is trigger a button
			'buttonClass1': null, //triggers class name before click
			'buttonText1': null, //triggers button name before click
			'buttonClass2': null,  //triggers class name after click
			'buttonText2': null,  //triggers button name after click
			'limitSelection': false, //remove other select options
			'chgTxt': null, //element of text
			'authorize': false,
			'authorizeClass': 'authorized',
			'authorizeTrigger': null,
			'fadeInTime': 300,
			'fadeOutTime': 300,
			'DelayTime': 300,
			'allBtnClass': 'button',
			'callback': null
		};

		var settings = $.extend({}, defaults, options);

		return this.each(function () {
			var obj = $(this),
				o = settings,
				msg = o.msg,
				attachMsg = o.attachMsg,
				triggerType = o.triggerType,
				buttonTrigger = o.buttonTrigger,
				buttonClass1 = o.buttonClass1 !== null ? ' ' + o.buttonClass1 : '',
				buttonText1 = o.buttonText1 !== null ? ' ' + o.buttonText1 : '',
				buttonClass2 = o.buttonClass2 !== null ? ' ' + o.buttonClass2 : '',
				buttonText2 = o.buttonText2 !== null ? ' ' + o.buttonText2 : '',
				limitSelection = o.limitSelection,
				chgTxt = o.chgTxt,
				authorize = o.authorize,
				authorizeClass = o.authorizeClass,
				authorizeTrigger = o.authorizeTrigger,
				fadeInTime = o.fadeInTime,
				fadeOutTime = o.fadeOutTime,
				DelayTime = o.DelayTime,
				allBtnClass = o.allBtnClass,
				callback = o.callback,
				modalLink = obj.find('a');

			if (authorize) {
				setupAshModal(modalLink);
			}

			var msgFrame = '<span class="progressMsg">' + msg + '</span>'; //status message frame

			function showMessage() {
				$(msgFrame).hide().appendTo(attachMsg).center().fadeIn(fadeInTime).delay(DelayTime).fadeOut(fadeOutTime, function () {
					$(this).remove();
				});
			}

			function handleAuthorization(trigger) {
				//trigger authorize modal
				trigger.find('.modalOpen').trigger('click.ashModal');
				//clear checkboxes of any selected facilities
				$('.' + allBtnClass).find('input[type=checkbox]').prop('checked', false);

				//function to handle click of authorization button in modal
				$('.' + authorizeTrigger).off('click').on('click', function () {
					trigger.find('input[type=checkbox]').prop('checked', true).trigger('change');
					callback(trigger);
				});
			}

			function invokeChange(trigger) {
				var triggerChecked = trigger.find('input[type=checkbox]').prop('checked') || trigger.hasClass('authorized'),
					buttonClass;

				//if trigger is checked attached message in the center of the screen, fade in, delay, fadeout and remove msg
				if (triggerChecked) {
					showMessage();
					if (limitSelection) {
						buttonClass = $.trim(buttonClass1);
						$('.' + allBtnClass + '.' + buttonClass).not(trigger).hide();
					}
					//if trigger is a button change the button class and text triggered settings
					if (buttonTrigger) {
						trigger.removeClass('primary' + buttonClass1).addClass('secondary' + buttonClass2);
						if (chgTxt !== null) {
							trigger.find(chgTxt).text(buttonText2);
						}
					}
					callback(trigger);
				} else {
					if (limitSelection) {
						buttonClass = $.trim(buttonClass1);
						$('.' + allBtnClass + '.' + buttonClass).show();
					}
					//if trigger is a button change the button class and text back to the original settings
					if (buttonTrigger) {
						trigger.removeClass('secondary' + buttonClass2).addClass('primary' + buttonClass1);
						if (chgTxt !== null) {
							trigger.find(chgTxt).text(buttonText1);
						}
					}
					callback(trigger);
				}
			}

			if (triggerType === 'checkbox') {
				obj.find('input').on('click.select', function (e) {
					e.stopPropagation();
					var el = $(this).parent();
					if (authorize) {
						handleAuthorization(el);
					} else {
						invokeChange(el);
					}
				});
			} else {
				showMessage();
			}
		});
	};

	//ASHMODAL
	$$ash.openModal();

	$('.radioFilter').each(function () {
		var ashTrig = $(this),
			//use radioFilterCont for normal cases, survey-question for true surveys (ie: PHA)
			filterItem = ashTrig.closest('.radioFilterCont').length ? ashTrig.closest('.radioFilterCont').find('.ashFilter') : ashTrig.closest('.survey-question').find('.ashFilter');
		ashTrig.ashFilter({
			event: 'change',
			trigger: ashTrig.find('.radioFilterTrigger'),
			filterItem: filterItem,
			addSelected: 'selected',
			fadeInDur: 1,
			fadeOutDur: 0
		});
	});
	//checkboxes with show/hide radio questions
	$('.checkbox-radio-group').each(function () {
		var ashTrig = $(this);
		ashTrig.ashFilter({
			event: 'change',
			trigger: ashTrig.find('.checkbox-radio-trigger'),
			filterItem: ashTrig.find('.checkbox-radio-filter'),
			fadeInDur: 1,
			fadeOutDur: 0,
			offOption: true
		});

		ashTrig.on('change.filterTriggerValidation', function () {
			var obj = $(this),
				radCont = obj.find('.radio-container'),
				valCont = obj.find('.val-cont .radio-container'),
				radContSib = radCont.siblings('.tooltip-validation');

			if (obj.find('.checkbox-radio-trigger').is(':checked')) {
				radCont.not('.validate').addClass('validate');
			} else {
				valCont.removeClass('validate watching').unwrap();
				radContSib.remove();
				$$ashVal.clearValidation(radCont);
				obj.find('input[type=radio]').prop('checked', false);
			}

			if ($('form.validate').length) {
				$$ashVal.init();
			}
		});
	});
	//hide radio buttons inside checkboxes when checkbox is unchecked
	$('.checkbox-radio-group').each(function () {
		var radioGroup = $(this),
			checkboxParent = radioGroup.find('input[type=checkbox]');
		$(checkboxParent).on('change', function () {
			var thisCheckbox = $(this);
			if (thisCheckbox.prop('checked')) {
				thisCheckbox.parent().parent().find('.subquestion-cont').find('input[type=radio]').prop('checked', false);
			}
		});
	});
	//TODAYS CHALLENGE LOG
	if ($('.challenge-log-select').length) {
		var chlgDC = new DateController(),
			chlgDCSel = $('.challenge-log-select'),
			currUrl = chlgDCSel.attr('data-current-json-url'),
			chlgDP = chlgDCSel.find('.date-period');

		var buildControl = function () {
			chlgDC.initialize('challenge-log');
			chlgDC.getData(chlgDCSel, currUrl);
			var start = chlgDP.attr('data-date-start'),
				end = chlgDP.attr('data-date-end'),
				type = chlgDCSel.attr('data-date-type');

			$('button.template-render-init').attr('data-json-url', currUrl);
			chlgDCSel.attr('data-current-json-url', currUrl);
		};
		var VMBtn = function (entries) {
			if ($.isArray(entries)) {  //if entries are an array, send in length
				showHideVMBtn(entries.length);
			} else if (!isNaN(entries)) {
				showHideVMBtn(entries);
			} else {  //if neither array or number hide button
				$('button.template-render-init').hide();
			}
		};
		var showHideVMBtn = function (numRecs) { //show hide 'view more' button
			var btn = $('button.template-render-init'),
				sel = $('.template-render-init').not('button'),
				recs = parseInt(numRecs),
				load = parseInt(chlgDCSel.attr('data-number-to-load'));

			if (isNaN(recs) || isNaN(load)) {  //if no number to load or number of records, show button
				btn.hide();
			} else {
				if (recs > load) {
					//if more recs than num to load show button
					btn.show();
				} else {
					//if not, hide button
					btn.hide();
				}
			}
		};

		var viewAll = function () {
			var callback = function (trigr, jsonData, target, endSlice) {
				target = $('button.template-render-init') || target;

				if (trigr.is('option')) {
					target.click(function () {
						$$ash.getTemplateData(target, target.attr('data-json-url'), target.attr('data-ash-template-url'), target.attr('data-ash-target-id'), callback);
					});
				} else {
					target.hide(); //remove show all button
				}
			};
			$$ash.templateInitHelper(callback);
			$('button.template-render-init').attr('data-json-url', currUrl);
		};

		buildControl();
		viewAll();

		$('#template-target').on('click', chlgDCSel.find('a[data-json-url]'), function (e) {
			var eTarget = $(e.target);

			if (eTarget.attr('href')) {
				//let links pass through
			} else {

				e.preventDefault();

				var obj = $(this),
					trigrBtn = eTarget.parent();

				if (trigrBtn.attr('data-json-url') !== undefined) {
					//click date control to add custom date
					currUrl = trigrBtn.attr('data-json-url');

					$$ash.getTemplateData(chlgDCSel, currUrl, chlgDCSel.attr('data-ash-template-url'), 'template-target', updateCurrent);
				}
			}
			function updateCurrent(trigr, jsonData, target, endSlice) {
				buildControl();
				viewAll();
				VMBtn(jsonData.items);
			}
		});
		$('#template-target + .btn-cont button').on('click', function () {
			$(this).hide();
		});
	}
	//SET UP SHARE FUNCTIONALITY
	$('a.share-social').on('click.social', function () {
		var twt = $('.social.twitter'),
			fbShare = $('.social.facebook');

		if (twt.length) {
			twt.attr('href', twt.attr('href') + '?text=' + twt.data('twitter-msg') + '&url=' + $$siteSettings.url);
		}
		if (fbShare.length) {
			$.ajaxSetup({ cache: true });

			if (typeof FB === 'undefined') {
				$.getScript('//connect.facebook.net/en_US/all.js', function () {
					FB.init({
						appId: $$siteSettings.fbAppId,
					});

					fbShare.click(function (e) {
						var obj = $(this);

						e.preventDefault();

						FB.ui({
							method: 'share_open_graph',
							action_type: $$siteSettings.fbNamespace + ':complete',
							action_properties: JSON.stringify({
								"challenge": obj.attr('href'),
							})
						}, function () {

						});
					});
				});
			}
		}
	});

	$$ash.updateProgress();

	if ($('.removeOnSubmit').length) {
		(function replaceButtonOnSubmit() {
			var btn = $('.removeOnSubmit'),
				form = btn.closest('form'),
				loader = btn.siblings('.progress-indicator');

			form.on('submit.removeOnSubmit', function (e) {
				var obj = $(this),
					btnDetached;

				if (!form.hasClass('ajaxPost') && !form.find('.validation-fail').length) {
					btnDetached = btn.detach();

					if (loader.length) {
						loader.css('display', 'inline-block');
					}

					obj.on('submit.preventAnotherSubmit', function (e) {
						e.preventDefault();
					});
				}
			});
		})();
	}

	$('.videoLoad').each(function () {
		var obj = $(this),
			vidUrl = obj.attr('data-video-address'),
			vidCont = obj.find('.videoCont'),
			width,
			height;

		//utilize vidCont wrapper class to give video and placeholder 16x9 aspect ratio
		if (vidCont.length > 0) {
			//maintain 16x9 aspect ratio for placeholder image
			$(window).on('load resize', function () {

				//delay calculation of width to ensure proper width is captured
				setTimeout(function () {
					width = vidCont.width();
					vidCont.css('height', width * (9 / 16));
				}, 20);

			});
		}

		//if no vidCont wrapper, video is the same size of the container that the placeholder was in
		else {
			height = obj.height();
			width = obj.width();
		}

		//replaces placeholder with video
		obj.on('click', function () {
			$$ash.ajax({
				dataType: 'jsonp', //TODO: change to 'json' once IE8/9 support is dropped
				url: '//noembed.com/embed?url=' + encodeURIComponent(vidUrl) + '&maxwidth=' + width + '&width=' + width + '&height=' + height + '&autoplay=1'
			}).done(function (data) {
				var vidMarkup = data.html;

				//replace placeholder with markup from noembed
				obj.replaceWith(vidMarkup);

				//remove metadata table that is added with video
				$('.noembed-meta-info').remove();
			});
		});

	});

	function initRadioCardSelections() {
		var radioCards = $('.radio-card');

		if ($('.radio-card').length) {
			(function () {
				function changeState(e, obj) {
					var objLabel = obj.closest('label'),
						objLabelSib = objLabel.siblings(),
						objInput = obj.find('input');

					e.stopPropagation();

					if (!objInput.prop('checked')) {
						objInput.prop('checked', true);
						objInput.focus();
					} else {
						objLabel.removeClass('radio_off').addClass('radio_on').trigger('change');
						objLabelSib.removeClass('radio_on').addClass('radio_off');
					}
				}

				radioCards.each(function () {
					var obj = $(this),
						objLabel = obj.closest('label'),
						objInput = obj.find('input');

					if (obj.hasClass('image')) {
						//IE8 issue where clicking an image does not trigger a change
						objLabel.on('click', function (e) {
							changeState(e, obj);
						});
					} else {
						objInput.on('change click', function (e) {
							changeState(e, obj);
						});
					}
					//if radio is already checked on page load
					if (objInput.prop('checked')) {
						objInput.trigger('click');
					}
				});
			})();
		}
	}

	//populate fields for enrollment forms
	if (typeof $$popVals !== 'undefined') {
		var parent = $('[data-populate-name]'),
			popName = parent.attr('data-populate-name'),
			obj = parent.find('[data-populate-item="' + popName + '"]'),
			fields = parent.find('input, select, textarea').not('[data-populate-item], .select2-offscreen, .select2-input'),
			fieldVals = $$popVals;

		obj.on('click', function () {
			if ($(this).is(':checked')) {
				$$ash.populateFields(fields, fieldVals, true, false);
			} else {
				$$ash.populateFields(fields, null, false, false);
			}
		});

		fields.on('blur', function () {
			obj.prop('checked', false);
		});
	}

	$('.viewMore').initViewMoreButton();

	$('.ajaxSearch').ajaxSearchInit();

	//PLAN SUMMARY INIT
	if ($('.plan-summary').length && $('.template-render-init').length && $('.template-render-init')[0].hasAttribute('data-ash-target-id')) {
		(function initTableCard() {
			var dropdown = document.querySelector('select.template-render-init');
			var targetId = dropdown.getAttribute('data-ash-target-id');
			var templateUrl = dropdown.getAttribute('data-ash-template-url');
			var jsonUrl = dropdown.options[0].getAttribute('data-json-url');

			//TODO: Remove jQuery wrapper from dropdown variables once handlebarsInit and renderTemplate have been converted to vanilla javascript

			//get first set of data from dropdown and render template on page
			$$ash.getTemplateData($(dropdown), jsonUrl, templateUrl, targetId);

			//initialize handlebarsInit method on dropdown change
			//dropdown wrapped in jQuery wrapper to talk to select2
			$(dropdown).on('change.planSummaryDropdown', function (e) {
				jsonUrl = dropdown.options[dropdown.selectedIndex].getAttribute('data-json-url');

				$$ash.handlebarsInit($(dropdown), {
					target: targetId,
					templateUrl: templateUrl,
					jsonUrl: jsonUrl
				});
			});
		}());
	}

	//initialize enableOnCheck
	$$ash.enableOnCheck();

});//END DOCUMENT READY

$(window).on('load', function () {
	if ($('.collapsible-header').length) {
		$('.collapsible-header').each(function () {
			var obj = $(this),
				collBdy = obj.next();
			//collapsibleAnimation = new TimeLite();
			obj.on('click', function () {
				//Collapsible header for mobile
				if (Modernizr.mq($$ash.mq('sm')) && (obj.hasClass('mobile-show'))) {
					if (collBdy.hasClass('expanded')) {
						TweenLite.to(collBdy, 0.3, { height: 0 });
						collBdy.removeClass('expanded');
					} else {
						collBdy.addClass('expanded');
						collBdy.css({ 'height': 'auto' });
						TweenLite.from(collBdy, 0.3, { height: 0 });
					}
				}
				else if (!obj.hasClass('mobile-show')) {
					if (collBdy.hasClass('expanded')) {
						collBdy.css({ 'height': '0' });
						TweenLite.from(collBdy, 0.3, { height: 'auto' });
						collBdy.removeClass('expanded');
					} else {
						collBdy.addClass('expanded');
						collBdy.css({ 'height': 'auto' });
						TweenLite.from(collBdy, 0.3, { height: 0 });
					}
				}

			});
			if (obj.hasClass('mobile-show')) {
				$(window).on('resize.obj orientationchange.obj', function () {
					if (Modernizr.mq($$ash.mq('sm', 'min'))) {
						collBdy.addClass('activated');
						if (collBdy.hasClass('activated')) {
							collBdy.css('height', 'auto');
							collBdy.removeClass('activated');
						}
					}
					else {
						collBdy.css('height', '0');
						collBdy.removeClass('expanded');
					}
				});
			}

		});
	}
	$('.ashModalPrint').on('click', function (e) {
		e.preventDefault();
		$$ash.ashModalPrinter($('.logo-cont img'), $(this).closest('.ashModalPanel').html(), $('link'));
	});
	function triggerPrint(theTrigger, printContent, link) {
		theTrigger.on('click', function (e) {
			e.preventDefault();
			$$ash.ashModalPrinter(null, $(this).closest(printContent).html() || (printContent).html(), link);
		});
	}
	//Call TriggerPrint and pass the print trigger, the container with the content to be printed, and link
	triggerPrint($('.tab-template .print-selection'), $('.tab-template'), $('link'));

	//Call TriggerPrint for PHA Disclosure
	triggerPrint($('.print-selection'), $('#PHADisclosureText'), $('link'));

	if ($('form.validate').length) {
		$$ashVal.init();
	}

	//OPEN MODAL ON PAGE RENDER
	$$ash.renderModal();

	//if there is no sticky nav and jump is a param in the url, run jumpToContent
	if (!$('.ash-sticky-nav').length && $.urlParam('jump') !== null && $(window).scrollTop() === 0) {
		$.jumpToContent();
	}

	//CONTACT US MESSAGE LENGTH COUNTDOWN
	$('.ash-countdown').each(function () {
		$(this).ashCountdown();
	});

	//CONTACT US MESSAGE LENGTH NON-label wrapped
	$('.ash-countdown-nonWrap').each(function () {
		var obj = $(this);
		obj.ashCountdown({
			'counter': obj.parent().parent().find('.ash-countdown-counter')//did parent parent because of valcont container
		});
	});

	(function setupShareEmail() {
		var obj = $('#share-email');

		if (obj.length) {
			var btn = obj.find('input[type=submit]'),
				ind = obj.find('.progress-indicator');

			function shareEmail(response, textStatus, xhr, cbOpts) {
				var obj = cbOpts.ele,
					jsonData = $$ash.unstringJSON(response);

				obj.find('label input').val('');

				if (jsonData.error === true) {
					$$ash.ajaxFail(obj, jsonData.errorMessage);
				} else {
					//add success message to the form
					$$ash.formSuccess(obj, jsonData);
				}
			}

			obj.on('submit.email', function (e) {
				var ajaxData = $$ash.serializeDataToObject(obj);

				$$ash.ajaxFormSubmit(obj, {
					data: ajaxData,
					cbOpts: { ele: obj },
					callback: shareEmail
				});
			});
		}
	})();

	$$ash.preventDefaultFormSubmit();
}); // END WINDOW ON LOAD

$(window).on('load resize', function () {
	$$ash.flexheightFallback();
	$$ash.cardsEqualHeight();
});
