//handlebars partial
if (typeof Handlebars !== 'undefined') {
	//HELPER FUNCTION TO HANDLE RANK ICON CLASSES
	Handlebars.registerHelper('rankIcon', function (object) {
		var rank = object;

		rank = rank.replace(" Place", "");

		if (!(rank === "1st" || rank === "2nd" || rank === "3rd")) {
			rank = "rank";
		}

		return new Handlebars.SafeString(rank);
	});

	//HELPER FUNCTION TO HANDLE RANK COLOR CLASSES
	Handlebars.registerHelper('rankColor', function (object) {
		var rank = object;

		rank = rank.replace(" Place", "");

		if (rank === "1st" || rank === "2nd" || rank === "3rd") {
			rank = " rank-" + rank;
		} else {
			rank = "";
		}

		return new Handlebars.SafeString(rank);
	});

	//HELPER FUNCTION TO HANDLE RANK COLOR CLASSES
	Handlebars.registerHelper('rankNum', function (object) {
		var rank = object;

		rank = rank.charAt(0);

		return new Handlebars.SafeString(rank);
	});

	//HELPER FUNCTION TO HANDLE COMPARISONS
	Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
		switch (operator) {
			case '==':
				return (v1 == v2) ? options.fn(this) : options.inverse(this);
			case '!=':
				return (v1 != v2) ? options.fn(this) : options.inverse(this);
			case '===':
				return (v1 === v2) ? options.fn(this) : options.inverse(this);
			case '!==':
				return (v1 !== v2) ? options.fn(this) : options.inverse(this);
			case '<':
				return (v1 < v2) ? options.fn(this) : options.inverse(this);
			case '<=':
				return (v1 <= v2) ? options.fn(this) : options.inverse(this);
			case '>':
				return (v1 > v2) ? options.fn(this) : options.inverse(this);
			case '>=':
				return (v1 >= v2) ? options.fn(this) : options.inverse(this);
			case '&&':
				return (v1 && v2) ? options.fn(this) : options.inverse(this);
			case '||':
				return (v1 || v2) ? options.fn(this) : options.inverse(this);
			default:
				return options.inverse(this);
		}
	});

	//HELPER FUNCTION TO REPLACE RETURNS AND LINEBREAKS WITH <BR />
	Handlebars.registerHelper('linebreaks', function (inpStr) {
		inpStr = Handlebars.Utils.escapeExpression(inpStr);
		inpStr = inpStr.replace(/(\r\n|\n|\r)/gm, '<br />');
		return new Handlebars.SafeString(inpStr);
	});

	Handlebars.registerHelper('connectedWidget', function (activityType) {
		//return icon name based off activityType
		switch (activityType) {
			case 'Steps':
				return new Handlebars.SafeString('walking');
			case 'Minutes':
				return new Handlebars.SafeString('Minutes');
			case 'Check-ins':
				return new Handlebars.SafeString('valid');
			default:
				return new Handlebars.SafeString('default');
		}
	});

	Handlebars.registerHelper('connectedHasData', function (links) {
		var linksLength = links.length,
			result = '';

		if (linksLength === 0) {
			throw new Error('The supplied JSON data does not contain any link information');
		}

		for (var i = 0; i < linksLength; i++) {
			if (i === 0) {
				result += '<a href="' + links[i].url + '" title="' + links[i].name + '">' + links[i].name + '</a>';
			} else {
				result += ' | <a href="' + links[i].url + '" title="' + links[i].name + '">' + links[i].name + '</a>';
			}
		}
		return new Handlebars.SafeString(result);
	});

	Handlebars.registerHelper('connectedNoDevice', function (links, exception, remove) {
		//if an exception is set, remove the link requested
		var exempt = exception || 0;
		if (exempt === true) {
			for (var i = 0; i < links.length; i++) {
				if (links[i].name == remove) {
					links.splice(i, 1);
					break;
				}
			}
		}

		var linksLength = links.length,
			result = '';

		if (linksLength === 0) {
			throw new Error('The supplied JSON data does not contain any link information');
		}

		for (var i = 0; i < linksLength; i++) {
			if (i === 0) { //TODO(?): USE CORRECT TITLES
				result += '<a href="' + links[i].url + '" title="' + links[i].name + '">' + links[i].name + '</a>';
			} else if (i > 0 && i < linksLength - 1) {
				result += ', <a href="' + links[i].url + '" title="' + links[i].name + '">' + links[i].name + '</a>';
			} else if (i === linksLength - 1) {
				if (linksLength == 2) {
					result += ' or <a href="' + links[i].url + '" title="' + links[i].name + '">' + links[i].name + '</a>';
				} else {
					result += ', or <a href="' + links[i].url + '" title="' + links[i].name + '">' + links[i].name + '</a>';
				}
			}
		}
		return new Handlebars.SafeString(result);
	});

	//HELPER FUNCTION TO HANDLE QUIZ ANSWER TYPE OF ICON, WILL DISPLAY A CHECKMARK, INCORRECT OR CROSS ICON
	Handlebars.registerHelper('validateIcon', function (validation) {
		if (validation === 'correctAnswer') {
			return 'icon-verified';
		} else if (validation === 'incorrectAnswer') {
			return 'icon-IncorrectAnswer';
		} else if (validation === 'noAnswer') {
			return 'icon-InValid';
		}
	});

	//HELPER FUNCTION TO TEST FOR APPS SYNCED WTIH USER'S ACCOUNT. IF NOT, THE ENTIRE SECTION DOES NOT DISPLAY
	Handlebars.registerHelper('appSynced', function (object, options) {
		var appsLength = object.length;

		for (var i = 0; i < appsLength; i++) {
			if (object[i].synced) {
				return options.fn(this);
			}
		}
	});

	//HELPER FUNCTION TO REMOVE SLASHES IN DATE
	Handlebars.registerHelper('slashlessDate', function (inpStr) {
		inpStr = Handlebars.Utils.escapeExpression(inpStr);
		inpStr = inpStr.replace(/\//g, '');
		return new Handlebars.SafeString(inpStr);
	});

	Handlebars.registerHelper('isPublic', function () {
		var pubClass = $('.isPublic');

		if (pubClass.length) {
			return true;
		} else {
			return false;
		}
	});

	//pass in icon id name --> return icon markup
	Handlebars.registerHelper('getIcon', function (icon) {
		var iconMarkup = '<svg><use xlink:href="#' + icon + '-icon"></use></svg>';

		return new Handlebars.SafeString(iconMarkup);
	});

	//general formatter that can accept different types (e.g., phone, date, etc) and spit out formatted version of the string
	Handlebars.registerHelper('ashFormat', $$ash.formatter);

	Handlebars.registerHelper('modalData', function (modals) {
		sessionStorage.setItem('ash-fs-modals', JSON.stringify(modals));
	});

	// Returns a string of "unit/timespan", unless the timespan is equal to "none"
	Handlebars.registerHelper('unitTimespan', function (unit, timespan) {
		if (timespan === 'None') {
			return unit;
		}
		return unit + '/' + timespan;
	});

	// Convert nouns representing time spans into adverbs
	Handlebars.registerHelper('toAdverb', function (noun) {
		if (noun === 'Week') {
			return 'Weekly';
		} else if (noun === 'Day') {
			return 'Daily';
		}
		return 'Once';
	});

	Handlebars.registerHelper('openNewTabForExternalLinkURL', function (linkURL) {
		if (linkURL.toLowerCase().indexOf('/external/') >= 0 || linkURL.indexOf('.pdf') >= 0 || !(linkURL.indexOf('/') == 0 || linkURL.indexOf('../') == 0)) {
			return new Handlebars.SafeString('target = "_blank"');
		}
	});

	// Checks global space for an asset prefix. If none is found, it returns the passed prefix or '' if a default is not supplied. 
	// Otherwise uses the asset prefix to return the base asset location.
	Handlebars.registerHelper('assetPrefixBase', function (defaultPrefix) {
		if (typeof defaultPrefix !== 'string') defaultPrefix = '';
		return (typeof window.$$assetPrefix === 'string') ? window.$$assetPrefix + '/Base/assets' : defaultPrefix;
	});
}