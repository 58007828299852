var GlAshCo = {
	startTemplate: function (source, template, id, skipJSON, callback) {
		var obj = {};
		//if not getting data from an external json file and json is passed in as a string
		if (skipJSON) {
			//parse string into JSON
			obj.data = $$ash.unstringJSON(source);
			$.when(
				$.get(template, function (tmp) {
					obj.template = tmp;
				})
			).then(
			//success
				function () {
					GlAshCo.renderTemplate(obj.template, obj.data, id, true, callback);
				},
			//fail
				function () {
					GlAshCo.renderTemplate(obj.template, obj.data, id, false);
				}
			);
		} else {
			$.when(
				$.getJSON(source, function (data) {
					obj.data = data;
				}),
				$.get(template, function (tmp) {
					obj.template = tmp;
				})
			).then(
			//success
				function () {
					GlAshCo.renderTemplate(obj.template, obj.data, id, true, callback);
				},
			//fail
				function () {
					GlAshCo.renderTemplate(obj.template, obj.data, id, false);
				}
			);
		}
	},

	renderTemplate: function (templateHtml, data, id, isSuccess, callback) {
		var ele = document.getElementById(id);
		//if get json and get template are successful
		if (isSuccess) {

			var tmp = Handlebars.compile(templateHtml),
				result = tmp(data);
			$(ele).html(result);
			//if teaser-blocks are added to the page, run teaser-blocks script
			if ($('section.teaser-blocks').length > 0) {
				GlAshCo.teaserBlocks();
				if( typeof(callback) == 'function' ){
					callback();
				}
			}
			if ($('html.oldie .timeline').length > 0) {
					GlAshCo.timelineOldie();
			}
			if ($('.timeline').length > 0) {
				GlAshCo.timeline();
			}

		} else {
			$(ele).html('Sorry, an error occured. Please try again or contact us.');
		}
	},
	teaserBlocks: function () {
		var teaserList = $('html.oldie .teaser-blocks ul');
		//add css selectors to oldie to style background
		teaserList.find('li:nth-of-type(2),li:nth-of-type(4n + 2)').addClass('item2');
		teaserList.find('li:nth-of-type(3),li:nth-of-type(4n + 3)').addClass('item3');
		teaserList.find('li:nth-of-type(4),li:nth-of-type(4n + 4)').addClass('item4');

		$('html.no-csstransitions section.teaser-blocks ul li a').each(function () {
			GlAshCo.teaserBlockAnimate($(this));
		});

	},
	timelineOldie: function () {
		var eventList = $('html.oldie .timeline');
		eventList.find('.event:nth-of-type(even)').addClass('even');
		eventList.find('.event:nth-of-type(odd)').addClass('odd');
		eventList.find('.year-highlight:nth-of-type(odd)').addClass('oddYear');
		eventList.find('.year-highlight:nth-of-type(even)').addClass('evenYear');
	},
	timeline: function () {
		$('.nav-timeline a[href*=\\#]:not([href=\\#])').click(function () {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				if ($('.fitness-timeline .nav-timeline').length > 0) {
					$('html,body').animate({
						scrollTop: target.offset().top - 208
					}, 1000);
					return false;
				}
				else {
					$('html,body').animate({
						scrollTop: target.offset().top - 190
					}, 1000);
					return false;
				}
			}
		});

		var newHeight = 0;
		var newEvtHeight = 0;
		var lastHeight = 0;
		var contHeight = 0;
		var newContHeight = 0;
		var timelineContent = $('.timeline .content');

		$('.event-container').each(function () {
			var obj = $(this);
			contHeight = obj.outerHeight();
			newContHeight += contHeight;
		});

		$(window).on('load resize', function () {
			timelineContent.each(function (index) {
				var content = $(this);
				var section = content.closest('section');
				var line = section.find('.line');
				var content = section.find('.content');
				var event = section.find('.event');
				var total = event.length;

				event.each(function (index) {
					var obj = $(this);
					evtHeight = obj.height();
					newEvtHeight += evtHeight;
					if (index === total - 1) {
						lastHeight = evtHeight / 2;
					}
				});
				contentHeight = content.outerHeight(),
				evtMinLast = contentHeight - lastHeight;

				line.height(evtMinLast);
			});
		});
	},

	teaserBlockAnimate: function (obj) {
		var ashTeaserBlockPos = obj.css('right');
		obj.on('mouseenter', function () {
			obj.animate({ 'right': 0 }, { duration: 300 });
		}).on('mouseleave', function () {
			obj.animate({ 'right': ashTeaserBlockPos }, { duration: 300 });
		});
	}

};
//===============================================
//          CUSTOM JQUERY PLUGINS
//===============================================
(function ($) {
	$.fn.openFormModal = function(options){
		var s = $.extend({
			trigger: this,
			htmlFormContent: $('.htmlForm'),
			setFld: '',
			setVal: '',
			setRel: '',
			formSelector: 'form'
		}, options);
		var pgBody = $('body');
		//setup ashModal form
		var setup = function(){
			s.trigger.each(function (i, ele){
				$(ele).ashModal({
					'theContent': s.htmlFormContent,
					'removePageScroll': true,
					'closeContent': '<span class="icon-CloseModal" title="close"></span>'
				});
				//if form elements are needed to be sent to developer (hidden fields or necessary info)
				var setEmail = true;
				if( s.setRel !== '' ){
					$(ele).on('click', function(){
						if( setEmail ){
							setFormElem( s.setRel );
							setEmail = false;
						}
					});
				}
			});
		};
		function setFormElem( type ){
			switch( type ){
				case 'valToHid': //set hidden field to a value
				  $(s.setFld).val( $(s.setVal).val() );
				  break;
				case 'valToTxt': //set text are to value
				  $(s.setFld).text( s.setVal );
				  break;
				case 'valToEnd': //set to end of class
				  $(s.setFld).val( $(s.setFld).val() + '\n\n' + s.setVal );
				  break;
				default:
				  //none
			}
		}
		setup();
		pgBody.on('submit', s.formSelector, function (e) {
			e.preventDefault();
			$('.error').hide();
			var clickedForm = $(this);
			var postPage = clickedForm.attr('action');
			var gotcha = clickedForm.find('#urlGotcha').val();
			var clientError = 'There was an error in submitting your form, please refresh the page and try again.';
			var validationError = $$ashVal.validateAll(clickedForm);
			var response = {};
			if (!gotcha && !validationError) {
				$.when(
					//passed in url and form from submit form is the form clicked
					$.post(postPage, clickedForm.serialize())
						.done(function (data) {
							if (typeof data === 'string') {
								var parsedData = JSON.parse(data);
								response = parsedData;
							} else {
								response = data;
							}
						})
				).then(
				//success
					function () {
						if (response.error) {
							$('.error').html(response.errorMessage).show();
						} else {
							//send to Developers/Server and when it returns successfully close modal
							$('.hideData').hide(); //hide form fields
							$('.response').html(response.message); //show response
							$('.hideData input').val(''); //clear form fields to be reused
						}
					},
				//fail
					function () {
						$('.error').html(response.errorMessage).show();
					}
				);
			} else if (gotcha && !validationError) {
				$('.error').html(clientError).show();
			}

		});
	};
}(jQuery));
//===============================================
//          /CUSTOM JQUERY PLUGINS
//===============================================

//STICKY NAVS (WHEN YOU NEED TO STICK MULTIPLE NAVBARS)
//If the primary navbar needs to be sticky, please make it sticky via CSS first.
//To use, just add the class "ash-sticky-nav" to all navbars you want to "stick".
//You will have to style the sticky within the page's CSS, but the js will take care of the margin-top for you.
//Then call the ashStickyNavs() function in the site specific .js file WITHIN $(WINDOW).LOAD(FUNCTION(){ }) !!!
//For example: "if ($('.ash-sticky-nav')) {ashStickyNavs({isMainNavSticky: true});}"
function ashStickyNavs(options) {
	var defaults = {
		mainNav: $('header'), //what is the selector for the main navbar?,
		mainContent: $('.main-content'), //selector for main content
		footer: $('footer'), //selector for footer
		isMainNavSticky: false, //Is the main navbar already sticky?
		fixedClass: 'fixed-nav', //class name to give the sticky sections
		mobileBreak: 767 //screen must be wider than this for the plugin to kick in
	},
		s = $.extend({}, defaults, options),
		mainNavHeight,
		target = $('.ash-sticky-nav'),
		targetArray = [],
		targetPos = [],
		targetHeights = [],
		navOffset = [],
		contentOffset = [],
		footerPos;

	target.each(function () {
		targetArray.push(this);
	});

	function navReset() {
		target.removeClass(s.fixedClass).removeAttr('style');
		$('.nav-adjusted').removeAttr('style').removeClass('nav-adjusted');
	}

	function targetPosHeightFinder() {
		footerPos = s.footer.offset().top;
		mainNavHeight = s.isMainNavSticky ? s.mainNav.outerHeight(true) : 0;
		targetPos.length = targetHeights.length = navOffset.length = contentOffset.length = 0;

		navReset();

		target.each(function () {
			targetPos.push($(this).offset().top);
			targetHeights.push($(this).outerHeight(true));
		});

		for (var i = 0, j = targetArray.length; i < j; i++) {
			contentOffset.push((i === 0) ? targetHeights[i] : contentOffset[i - 1] + targetHeights[i]);
			navOffset.push((i === 0) ? mainNavHeight : contentOffset[i - 1]);
		}
		navSticker();

		if ($.urlParam('jump') !== null && $(window).scrollTop() === 0) { //jump to content, if jump is a param in the url
			$.jumpToContent();
		}
	}

	function navSticker() {
		var currentScrollPos = $(window).scrollTop();
		if ($(window).width() >= s.mobileBreak) {

			if (currentScrollPos >= targetPos[0] - navOffset[0] && currentScrollPos < footerPos - contentOffset[targetArray.length - 1]) {

				for (var i = 0, j = targetArray.length; i < j; i++) {
					if (currentScrollPos >= targetPos[i] - navOffset[i] || i === 0) {
						$(targetArray[i]).addClass(s.fixedClass).css('top', navOffset[i]);
						s.mainContent.addClass('nav-adjusted').css('padding-top', contentOffset[i]);
					} else {
						$(targetArray[i]).removeClass(s.fixedClass).removeAttr('style');
					}
				}
			} else {
				navReset();
			}
		} else {
			navReset();
		}
	}
	targetPosHeightFinder();

	$(window).on('orientationchange resize', targetPosHeightFinder).on('scroll', navSticker);
}

function addEventAfterCreation() {
	var collapsibleHeader = $('.collapsible-header');

	collapsibleHeader.ashCollapsible({
		'closedIconClass': 'icon-GreaterThan',
		'openedIconClass': 'icon-DropDown'
	});
	collapsibleHeader.each(function (ndx, ele) {
		if (ndx % 2 === 0) {
			$(ele).addClass('even');
		}
	});
}
//=====================================================================================================================
//          CONSTRUCTORS START
//==============================================================================
function AshcoModal(theTrigger, theContent) {
	var removeScroll = true,
		closeContent = '<span class="icon-CloseModal" title="close"></span>',
		callBack = addEventAfterCreation;

	theTrigger.ashModal({
		'theContent': theContent,
		'removePageScroll': removeScroll,
		'closeContent': closeContent,
		'callbackAfterClick': callBack
	});
}
//=============================================================================================================
//          END CONSTRUCTORS
//
//          DOCUMENT READY START
//==============================================================================
$(document).ready(function () {
	var mainContent = $('.main-content'),
		body = $('body'),
		bodyInner = $('.body-inner'),
		bulletin = $('.bulletin'),
		mobileBreak = 767;

	(function selectAnimateInit() {
		var selectObj = $('select'),
			placeholderData = null,
			mainContent = $('.main-content, .main'); //TODO: delete .main when we transition to new template markup

		if (selectObj.length && !Modernizr.touchevents) {
		    selectObj.each(function () {
		        var selectObject = $(this);
				placeholderData = selectObject.data('select2-placeholder');
				selectObject.select2({
				    placeholder: selectObject.data('select2-placeholder'),
				    minimumResultsForSearch: -1,
				    formatNoMatches: function (term) {
				    	return 'Loading...';
				    }
				});
			});

			mainContent.on('select2-open', 'select', function (e) {
				var dropdown = $('.select2-drop-active');

				dropdown.css('height', 'auto');

				TweenLite.from(dropdown, 0.5, {
					height: 0,
					ease: Power4.easeOut
				});
			});
		} else {
		    $$ash.removeSelectEmptyOption();
		}
	})();

	(function initModals() {
		var uracTrigger = $('.awards-urac-trigger'),
			ncqaTrigger = $('.awards-ncqa-trigger'),
            hitrustTrigger = $('.awards-hitrust-trigger'),
			uracModal,
			ncqaModal;

		if (uracTrigger.length) {
			uracModal = new AshcoModal(uracTrigger, $('.urac-faq'));
		}

		if (ncqaTrigger.length) {
			ncqaModal = new AshcoModal(ncqaTrigger, $('.ncqa-faq'));
		}
		if (hitrustTrigger.length) {
		    hitrustModal = new AshcoModal(hitrustTrigger, $('.hitrust-faq'));
		}
	})();

	$('.ashModalCall').each(function (i, ele) {
		$(ele).ashModal({
			'closeContent': '<span class="icon-CloseModal" title="close"></span>'
		});
	});

	function postData(url, form) {
		function formComplete(hideForm, str) {
			if (hideForm) {  //if we want to hide the form
				form.slideUp();
			}
			$('.formSubmitResult').html(str).slideDown();  //show display message on this tag
		}
		$.post(url, form) //passed in url and form from submit form is the form clicked
		.done(function (formdata) {
			formComplete(true, formdata);
		})
		.fail(function () {
			formComplete(false, 'There was an error in submitting your form, please try again');
		});
	}
	$('#contactus, #quick-app').on('submit', function (event) { //when clicking the form
		event.preventDefault();
		var clickedForm = $(this);
		var postPage = clickedForm.attr('action');
		postData(postPage, clickedForm); //call postData and pass the url where message is store
	});

	$('script.js-template-manager').each(function () {
		var obj = $(this),
			objSrc = obj.attr('src'), //src for json data
			dataSrc = objSrc || $('#templateData').html(), //if no src for data, use script tag in page
			inpageData = false; //default to false and override if src isn't present

		if (!objSrc) {
			inpageData = true;
		}
		GlAshCo.startTemplate(dataSrc, obj.attr('data-ash-template'), obj.attr('data-ash-id'), inpageData, createAwards);
	});

	if ($('.flexslider').length) {
		//HOMEPAGE js
		if ($('.carousel-services').length) {
			(function HomePageInit() {

				function slideshowInit() {
					var mainSlider = $('.carousel-services > .flexslider');

					mainSlider.flexslider({
						selector: '.slides > figure',
						animation: 'slide',
						slideshowSpeed: 15000,
						useCSS: true,
						directionNav: false,
						manualControls: '.ashFlexSlideTabs > div',
					});
				}

				slideshowInit();
			})();
		}

		//Basic flexslider
		if ($('.slideshow').length) {
			(function basicSliderInit() {
				var slideshow = $('.slideshow');

				slideshow.flexslider({ //ASHCo Careers Benefits & ASHCo Solutions and Services
					selector: '.carousel-reel .carousel-slide',
					animation: 'slide',
					slideshow: false,
					controlNav: slideshow.find('.carousel-slide').length < 2 ? false : true,
					directionNav: slideshow.find('.carousel-slide').length < 2 ? false : true,
					touch: slideshow.find('.carousel-slide').length < 2 ? false : true,
					manualControls: '.ashFlexSlideTabs li',
					prevText: '<span class="icon-DirectionCarouselLeft" aria-hidden="true"></span>',
					nextText: '<span class="icon-DirectionCarouselRight" aria-hidden="true"></span>'
				});
			})();
		}

		//OurCompany ManagementTeam slider
		if ($('.carousel-ourcompany').length) {
			(function mngmntTeamPageInit() {
				var mainSliderCont = $('.carousel-ourcompany'),
					mainSlider = mainSliderCont.find('.flexslider');

				function slideshowInit() {
					if ($('.ie8').length || Modernizr.mq($$ash.mq('sm', 'min'))) {
						mainSlider.flexslider({
							selector: '.carousel-reel .carousel-slide',
							animation: 'slide',
							slideshow: false,
							slideshowSpeed: 5000,
							manualControls: '.profile-tabs .data-gallery-item',
							prevText: '<span class="icon-DirectionCarouselLeft" aria-hidden="true"></span>',
							nextText: '<span class="icon-DirectionCarouselRight" aria-hidden="true"></span>',
							start: function (slider) {
								$('.dark-bg').prependTo(mainSliderCont);
							}
						});
						//show the flexslider and hide the cloned slider
						$('.carousel-reel-container').show();
					} else {
						//show the cloned slider and hide the flexslider
						$('.carousel-reel-container').hide();
					}
				}
				$(window).on('load resize', function () {
					slideshowInit();
				});
			})();
		}
	}

	var setupStats = function () {
		var collCont = $('.collection-accent span[class^=accent]'),
			holder = $('.collection-accent-holder'),
			slide = holder.parents('.collection-bar').prev();
		holder.removeClass('small');
		//reset holder
		collCont.find('span').attr('class', 'stat').html('');

		var actSlide = slide.find('.ashFlexSlideTabs li.flex-active').index(),
			statsLIs = $('.carousel-content .stats').eq(actSlide + 1).find('li'), //add 1 to active index since flexslider prepends last slide to first slide
			addNdx;

		if (statsLIs.length == 4) {
			holder.addClass('small');
			addNdx = 1;
		} else {
			addNdx = 0;
		}
		statsLIs.each(function (ndx) {
			var stat = collCont.eq(ndx + addNdx).find('.stat'),
				ballCont = $(this).text();

			if ($(this).html().indexOf('icon-') !== -1) {
				stat.attr('class', ballCont);
			} else {
				stat.text(ballCont);
			}
			stat.css({ opacity: 0 }).stop(true).animate({
				opacity: 1
			}, 1000);
		});
	};

	if ($('.carousel-patient-survey').length || $('.carousel-awesome-workplace').length) {
		setupStats();
		$('.carousel-patient-survey a.flex-prev, .carousel-patient-survey a.flex-next, .carousel-patient-survey .ashFlexSlideTabs li,.carousel-awesome-workplace a.flex-prev, .carousel-awesome-workplace a.flex-next, .carousel-awesome-workplace .ashFlexSlideTabs li').on('click', function (e) {
			e.preventDefault();
			setupStats();
		});
	}
	/*Awards carousel*/
	function calcSlides() {
		var as = $('.carousel-awards'),
			tabs = as.find('.ashFlexSlideTabs'),
			numSlides = as.find('.carousel-slide').length;

		setupTabs();

		function setupTabs() {
			for (var ndx = 0; ndx < numSlides - 1; ndx++) {
				tabs.append('<li><div></div></li>');
			}
		}

	}

	//add background url to hero images
	$.fn.getHerourl = function () {
		var obj = $(this),
			url = obj.attr('data-ash-herourl'),
			oldieStr,
			str;
		oldieStr = '../../global/images/ASHCO/background/hero-' + url + '.jpg';
		str = 'url(../../Global/images/ASHCo/background/hero-' + url + '.jpg)';
		if (!url) {
			return false;
		} else {
			$(obj).css('background-image', str);
			//if old IE apply scale filter
			if ($('html').hasClass('oldie')) {
				var heroHt = $('.hero').height();
				//add ieFrame scaffold to stretch backgound height
				$('.hero-overlay').wrap('<div class="ieFrame" style="height:' + heroHt + 'px;overflow:hidden;"><div class="ieBkgd"></div></div>');

				var toggleScaleFilterIE = function () {
					var newHt = Math.round($(window).width() / 1440 * 557);
					//if new natural height of scaffold is less that new height make the stretch the background
					if (heroHt < newHt) {
						$('.ieBkgd').css({
							'filter': 'progid:DXImageTransform.Microsoft.AlphaImageLoader(src="' + oldieStr + '", sizingMethod="scale")',
							'height': newHt
						});
					} else {
						//if new height smaller than natural height remove filter and height
						$('.ieBkgd').removeAttr('style');
					}
					$('.hero-overlay').height(heroHt);
				};
				toggleScaleFilterIE();

				$(window).on('resize', function () {
					//calculate height and apply scaling of the background
					toggleScaleFilterIE();
				});
			}
		}
	};
	$('div#hero').getHerourl();

	//ashModal for downloads
	$('.downloadFiles').find('input[type=submit]').openFormModal({
		htmlFormContent: $('.download-agreement'),
		setFld: '.download-file',
		setVal: '.downloadFiles select',
		setRel: 'valToHid',
		formSelector: '#download'
	});
	$('.emailTrigger').openFormModal({
		htmlFormContent: $('.emailModal'),
		setFld: '.emailMessage',
		setVal: $(location).attr('href'),
		setRel: 'valToEnd',
		formSelector: '#emailForm'
	});
	if ($('#emailTo').length) { //if emailTo field is set, add validation for multiple emails
		//this is a cursory validation that should be backed up by server validation
		$('.emailTrigger').on('click', function () {
			$('.hideData').show();  //show form fields and buttons
			$('.response, .error').html(''); //clear old messages
		});
		$('body').on('focus', '#emailTo', function () {
			$('.error').html('').hide();
		});
	}
	//contact page maps
	googleMapsInitialize = function () {
		var map,
			marker,
			point,
			addressContent = $('.contact-info .contact-address').text(),
			infowindow,
			mapOptions = {
				zoom: 13,
				center: new google.maps.LatLng(39.972635, -86.15887)
			};

		infowindow = new google.maps.InfoWindow({
			content: '<a href="#" onClick="map.closeInfoWindow()"><span class="icon-CloseModal"></span></a></code>' + addressContent
		});

		map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
		point = new google.maps.LatLng(39.972635, -86.15887);
		marker = new google.maps.Marker({
			position: point,
			map: map,
			title: "American Specialty Health",
			draggable: false,
			icon: '../../Global/images/ASHCo/Icon/icon-map-ash.png'
		});

		google.maps.event.addListener(marker, 'click', function () {
			infowindow.open(map, marker);
		});
	};
	//careers page maps
	googleMapsInitializeCareers = function () {
		var map,
			ashLocations = [
				['Temecula', 33.506127, -117.1820647, 3, '27524 Vía Industria, Bldg G<br>Temecula, CA 92590'],
				['San Diego', 32.900417, -117.205749, 4, '10221 Wateridge Circle<br>San Diego, CA 92121'],
				['El Cajon', 32.824180, -116.980156, 3, '1973 Friendship Drive<br>El Cajon, CA 92020'],
				['Dallas', 32.9545623, -97.1361909, 17.75, '950 E. State Hwy 114, Suite 200<br>Southlake, TX 76092'],
				['Columbia', 34.001854, -81.032114, 5, '1301 Gervais Street, Suite 1000<br> 10th Floor, Columbia, SC 29201'],
				['Indianapolis', 39.9740204, -86.1580132, 2, '12800 N. Meridian St.<br>Carmel, IN  46032']
			],
			mapOptions = {
				zoom: 5,
				center: new google.maps.LatLng(35.527046, -98.702395),
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
				}
			};

		map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
		setMarkers(map, ashLocations);

		function setMarkers(map, locations) {
			var image = {
				url: '../../Global/images/ASHCo/Icon/icon-map-ash.png',
				// This marker is 20 pixels wide by 32 pixels tall.
				size: new google.maps.Size(42, 42),
				// The origin for this image is 0,0.
				origin: new google.maps.Point(0, 0),
				// The anchor for this image is the base of the flagpole at 0,32.
				anchor: new google.maps.Point(0, 32)
			};
			var shape = {
				coord: [1, 1, 1, 20, 18, 20, 18, 1],
				type: 'poly'
			};

			for (var i = 0; i < locations.length; i++) {
				var address = locations[i][4],
					title = locations[i][0],
					content = '<span class="icon-CloseModal" title="close"></span>' + '<h3>' + title + '</h3>' + address,
					infowindow = new google.maps.InfoWindow(),
					location = locations[i],
					myLatLng = new google.maps.LatLng(location[1], location[2]),
					marker = new google.maps.Marker({
						position: myLatLng,
						map: map,
						icon: image,
						shape: shape,
						title: location[0],
						zIndex: location[3]
					});

				google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
					return function () {
						infowindow.setContent(content);
						infowindow.open(map, marker);
					};
				})(marker, content, infowindow));
			}
		}
	};

	//collapsible-panel for FAQ page
	$('.collapsible-faq dt').ashCollapsible();

	//collapsible-panel for Job Description page
	if ($('.collapsible-description').length) {
		$('.collapsible-header').ashCollapsible({
			'panel': $('.collapsible-body')
		});
	}

	$(window).on("load", function () {
		if ($('.invalid').length > 0) {
			$('.invalid:not(.select2-container)').each(function () {
				var obj = $(this);
				if (obj.is('select')) {
					obj.prev().nextUntil().andSelf().wrapAll('<span class="tooltip-wrapper"/>');
				} else {
					obj.next('span.tooltip-validation').andSelf().wrapAll('<span class="tooltip-wrapper"/>');
				}
			});
		}
	});

	function selectCheckCombo() {
		var sel = $('select.select-checkbox-combo'),
			selCopy = $('div.select-checkbox-combo'),
			selCopyChosen = selCopy.find('.select2-chosen'),
			placeholderText = selCopyChosen.text(),
			trigger = $('.select-checkbox-combo-trigger'),
			mainCont = $('.select-checkbox-combo-holder'),
			inpCont = $('.select-checkbox-combo-input-cont-inner'),
			slideMask,
			inpBtn = inpCont.find('button'),
			newHt = parseFloat(mainCont.find('input').first().not($(this)).height()),
			newOuterHt = parseFloat(mainCont.find('input').first().not($(this)).outerHeight(true));

		//change height of select and trigger to match other inputs
		sel.height(newHt);
		trigger.height(newOuterHt);

		inpCont.before('<div class="slide-mask hidden"></div>');
		slideMask = $('.slide-mask');

		function slideMaskToggle() {
			if (slideMask.hasClass('hidden')) {
				slideMask.removeClass('hidden');
			} else {
				slideMask.addClass('hidden');
			}
		}

		function toggleInp() {
			inpCont.slideToggle();
			slideMaskToggle();
		}

		trigger.on('click', toggleInp);

		slideMask.on('click', function () {
			inpCont.slideToggle();
			slideMask.addClass('hidden');
		});

		//when button is clicked...
		inpBtn.on('click', function (e) {
			e.preventDefault();
			toggleInp();
		});

		//show selected item instead of placeholder in dropdown input
		inpCont.find('input[type=checkbox]').on('change', function () {
			var input = $(this);
			var checkedNum = inpCont.find('input:checked').length;
			if (checkedNum === 0) {
				selCopyChosen.text(placeholderText);
			} else {
				if (input.prop('checked')) {
					if (checkedNum === 1) {
						selCopyChosen.text(inpCont.find('input:checked').val());
					} else {
						selCopyChosen.text(inpCont.find('input:checked').val() + ' ...');
					}
				} else {
					if (checkedNum === 1) {
						selCopyChosen.text(inpCont.find('input:checked').first().val());
					} else {
						selCopyChosen.text(inpCont.find('input:checked').first().val() + ' ...');
					}
				}
			}
		});
	}

	if ($('select.select-checkbox-combo').length) {
		selectCheckCombo();
	}

	$('html.no-csstransitions .thumbCont .window-overlay:not(html.no-csstransitions .thumbs-botinfo .thumbCont .window-overlay)').each(function () {
		var obj = $(this),
			cont = obj.closest('.thumbCont'),
			origTop = obj.css('top');
		cont.on('mouseenter', function () {
			obj.animate({ 'top': 0 }, 500);
		})
		.on('mouseleave', function () {
			obj.animate({ 'top': origTop }, 500);
		});
	});
	$('html.no-csstransitions .thumbs-botinfo .thumbCont .window-overlay').each(function () {
		var obj = $(this),
			cont = obj.closest('.thumbCont'),
			origTop = obj.css('top');
		cont.on('mouseenter', function () {
			obj.animate({ 'top': -144 }, 500);
		})
		.on('mouseleave', function () {
			obj.animate({ 'top': origTop }, 500);

		});
	});
	$('html.no-csstransitions .thumbs-botinfo.teaser-blocks .read-more').each(function () {
		GlAshCo.teaserBlockAnimate($(this));
	});
	function twitterCharCntr(twttrMsg, addlChars) {
		var cnt = twttrMsg.length + addlChars;
		if (cnt < 141) {
			return true;
		}
		return false;
	}
	function createTwttrMsg(title, location, xtraChars) {
		var lvl1 = title + '@ASHCompanies ' + location,
			lvl2 = title + '@ASHCompanies ',
			lvl3 = '@ASHCompanies is hiring. Apply now at';
		if (twitterCharCntr(lvl1, xtraChars)) {
			return lvl1;
		} else if (twitterCharCntr(lvl2, xtraChars)) {
			return lvl2;
		} else {
			return lvl3;
		}
	}

	//if teaser-blocks are added on doc ready instead of through JS template, add oldie classes for bg color
	if ($('html.oldie .teaser-blocks ul').length) {
		GlAshCo.teaserBlocks();
	}

	function createAwards() {
		if ($('#ourCo_awards_teaserBlocks').length) {
			var yrArr = [],
				awrdFltr = $('.awardsFilter');
			$('.carousel-awards .teaser .awardYear').each(function () {
				var year = $(this).text().replace("‘", "");
				if ($.inArray(year, yrArr) == -1) {
					yrArr.push(year);
				}
			});
			awrdFltr.append('<li class="active" data-ash-filter-trigger="all"><a href="#">All</a></li>');
			for (var ndx = 0; ndx < yrArr.length; ndx++) {
				var yrData = abbrYr = yrArr[ndx];
				if (yrData > 95) {
					yrData = '19' + yrData;
				} else {
					yrData = '20' + yrData;
				}
				awrdFltr.append('<li data-ash-filter-trigger="' + abbrYr + '"><a href="#">' + yrData + '</a></li>');
			}

			$('.awardsFilter').each(function () {
				var obj = $(this);
				obj.ashFilter({
					event: 'click',
					trigger: $(this).find('li'),
					filterItem: $('.ashFilter'),
					addSelected: 'active',
					fadeInDur: 1,
					fadeOutDur: 0
				});
				obj.on('click', 'a', function (e) {
					e.preventDefault();
				});
			});
		}
	}

	$('.contactFilter').each(function () {
		var ashTrig = $(this);

		ashTrig.ashFilter({
			event: 'change',
			trigger: $(this),
			filterItem: $('.ashFilter'),
			fadeInDur: 1,
			fadeOutDur: 0
		});
	});

    // Ensure that filter is triggered on load if dropdown is pre-selected
	$('.contactFilter').trigger('change');

	if ($('.ashTab').length) {
		$('.ashTab').ashTab({ iconClass: 'icon-GreaterThan' });
	}

    //init base offcanvas nav
	offCanvasNavInit();

	if (bulletin.length) {
		function moveBulletin() {
			if ($(window).width() < mobileBreak) {
				if (!bulletin.hasClass('moved')) {
					bulletin.prependTo(mainContent).addClass('moved');
				}
			} else {
				if (bulletin.hasClass('moved')) {
					bulletin.prependTo(bodyInner).removeClass('moved');
				}
			}
		}

		moveBulletin();

		$(window).on('resize orientationchange', moveBulletin);
	}

	if ($('html.oldie section.timeline').length) {
		GlAshCo.timelineOldie();
	}
	if ($('section.timeline').length) {
		GlAshCo.timeline();
	}

	//CHANGE LAYOUT OF CAREERS RESULTS TABLE FOR SMALLER BROWSERS
	if (Modernizr.mq($$ash.mq(850))) {
		$('.responsive.careers tr:not(:first-child)').each(function () {
			var jobOBJ = $(this),
				jobLink = $(this).find('td:first-child').find('a').attr('href');
			jobOBJ.wrapInner('<td class="col-10"><table><tr></tr></table></td>').append('<td class="col-2"><a href="' + jobLink + '"><span class="icon-GreaterThan"></span></a></td>');
		});
	}

	(function downloadIconInsert() {
		var downloadLink = $('.carousel-patient-survey h1 a');

		downloadLink.html('<span class="icon-Download"></span>');
	})();

	(function whitePaperModalSetup() {
		var obj = $('.downloadModalOpen'),
			modalContent = $('.ashModalCont[data-ash-ashmodalmatch="downloadModal"]');

		if (obj.length) {
			obj.ashModal({
				format: 'html',
				theContent: modalContent,
				closeContent: '<span class="icon-CloseModal"><span class="sr-only">remove</span></span>',
				callbackAfterClick: function(caller) {
					var form = $('form.whitePaper'),
						download = caller.attr('href');
					$$ashVal.init();

					form.off('submit.whitePaper')
						.on('submit.whitePaper', function () {
							var obj = $(this);

							$$ash.ajaxFormSubmit(obj, {
							    url: obj.attr('action'),
								data: obj.serialize(),
								callback: function () {
									window.location.href = download;
								}
							});
						});
				}
			});

			$('.closeModal').on('click.closeModal', function () {
				$(this).parents('.ashModalContent').find('.ashModalClose').click();
			});
		}
	})();

	(function setupAshModal() {
		var modals = document.querySelectorAll('.modalOpen');

		if (modals.length) {
			Array.prototype.forEach.call(modals, function(item){
				var modalMatch = document.querySelector('.ashModalCont[data-ash-ashmodalmatch="' + item.getAttribute('data-ash-ashmodalmatch') + '"]');
				var modalClass = item.getAttribute('data-ash-addmodalclass') ? item.getAttribute('data-ash-addmodalclass') : null;
				$(item).ashModal({
					'theContent': $(modalMatch),
					'closeContent': '<span class="icon-CloseModal" title="close"></span>',
					'addModalClass': modalClass,
					'removePageScroll': true
				});
			});
		}
	})();
});
//============================================================================================================
//          END DOCUMENT READY
//
//          WINDOW LOAD START
//====================================================================================
$(window).on("load", function () {
	var bulletin = $('.bulletin');

	if($('html.oldie section.timeline').length) {
		GlAshCo.timelineOldie();
	}
	if ($('section.timeline').length) {
		GlAshCo.timeline();
	}
	if ($('.search-block').length) {
		var sb = $('.search-block');
		$.fn.fixSearchBox = function () {
			var inp = $(this).find('input').first(),
				btn = $(this).find('button');
			if (inp.outerHeight() !== btn.outerHeight()) {
				btn.outerHeight(inp.outerHeight());
			}
		};
		sb.fixSearchBox();
	}
	if ($('#map-canvas').length > 0) {
		googleMapsInitialize();
	}
	if ($('.careers-locations #map-canvas').length > 0) {
		googleMapsInitializeCareers();
	}
	if (bulletin.length) { //older implementation fo this bulletin. please look at hyr.js for better implementation.
		bulletin.addClass('loaded');

		$('.close-bulletin').click(function (e) {
			e.preventDefault();
			bulletin.removeClass('loaded');
		});

		if ($('.ash-sticky-nav').length) {
			window.setTimeout(ashStickyNavs, parseFloat(bulletin.css('transition-duration').replace(/s/g, '')) * 1000);
		}
	}
	if (!bulletin.length) {
		if ($('.ash-sticky-nav').length) {
			ashStickyNavs();
		}
	}
});
//===============================================
//          /WINDOW LOAD
//===============================================
