//CLIENT-SIDE VALIDATION
;(function ($$ashVal, $, undefined) {
	var hasError = false; //flag indicating that there is/isn't a validation error
	var exclusiveCheckedClass = 'exclusiveChecked'; //class added to exclusive inputs when their associated checkbox is checked
	var parentInvalidClass = 'isInvalid'; //class added to input parent to indicate a validation error
	var errorMsgContClass = '.validation_message';
	var activeValClass = 'js-watching'; //class added to inputs to indicate they will be validated
	var valRulePrefix = 'data-val-'; //prefix of data attributes containing validation rules
	var wrapper = '<div class="inputField validation_container"></div>';
	var valMessageCont = '<div class="validation_message"></div>';
	var exclude = Modernizr.touchevents === true ? 'form' : 'form, input[type=date], div.select2-container';
	var valForm; //the form to validate

	//make this class global so other JS (ie: ashModal) can access it
	$$ashVal.valClass = 'validate', //class name of inputs to validate
	$$ashVal.failClass = 'invalidInput';
	//Custom callbacks for AJAX calls can be registered here. The relevant parameters will be passed
	//  to the function.
	$$ashVal.ajaxCallbacks = {
		error: null,
		beforeSend: null
	};
	//Array of HTTP error codes for which the default server error UI should not be displayed. This will be passed to $$ash.ajax() and used to determine if $$ash.ajaxFail() will be called in the case of an error.
	$$ashVal.ajaxIgnoreErrorDisplayCodes = [];

	$$ashVal.init = function () {
		valForm = $('form.validate');

		valForm.each(function () {
			var obj = $(this),
				valInputs;

			// delete this after scoping data validation and testing
			if (obj.hasClass('validateDate')) {
			    exclude = Modernizr.touchevents === true ? 'form' : 'form, div.select2-container';
			}
		    //end delete this

            // all inputs within the form that have 'validate' class
			valInputs = obj.find('.' + $$ashVal.valClass).not(exclude);

			valInputs.each(function () {
			    var obj = $(this);
                if (!obj.hasClass(activeValClass)) { //if input doesn't already have validation bound to it
                    $$ashVal.setUpMarkup(obj);
				}
			});

			$$ashVal.bindValidationToSubmit(obj, valInputs);
		});

		$$ashVal.bindConditionalValidation();
	};

	$$ashVal.setUpMarkup = function (obj) { //adds container wrapper and class names for ashVal
		var siblings = obj.siblings('div.select2-container').add(obj.nextAll('.tooltip-obj')).add(obj.nextAll('span')).not('.ash-countdown-counter, .checkbox-container, .radio-container');
		
		obj.addClass(activeValClass).add(siblings).wrapAll(wrapper);

		if (obj[0].hasAttribute('data-val-exclusive-name')) {
			obj.addClass('exclusiveVal');
		}

		$$ashVal.bindValidationToField(obj);
	};

	$$ashVal.bindValidationToField = function (obj) {
		var valEvent = obj.attr('data-val-event') || 'blur change',
			cxt = $$ashVal.preValidate(obj);
		if (obj.is('input, select, textarea')) {
			obj.on(valEvent, function () { //binds validation function to the event decided above
				$$ashVal.validate(obj, cxt.rules, cxt.errorText, cxt.parent);
			});
		}
		if (obj.hasClass('validate-error')) {
			$$ashVal.markInvalid(obj, obj.attr('data-val-msg'), cxt.errorText, cxt.parent);
		}
	};

	$$ashVal.unbindValidationFromField = function (obj) { //FIX THIS
		obj.removeClass('validate js-watching')
			.siblings(errorMsgContClass).remove().addBack().unwrap(wrapper);
	};

	$$ashVal.bindConditionalValidation = function () {
		var leaders = $('[data-val-conditional-target]');

		leaders.each(function () {
			var obj = $(this);
			var	follower = $('#' + obj.attr('data-val-conditional-target'));

			if (obj.is('input, select, textarea')) {
				obj.off('blur.ashVal').on('blur.ashVal', function () {
					if (obj.val().replace(/ /g, '').length !== 0) {
						if (!follower.hasClass(activeValClass)) { //if input doesn't already have validation bound to it
							follower.addClass($$ashVal.valClass);
							$$ashVal.setUpMarkup(follower);
						}
					} else if (follower.hasClass(activeValClass)) { //no value and follower has validation bound to it
						$$ashVal.unbindValidationFromField(follower);
					}
					$$ashVal.bindValidationToSubmit(follower.closest('form'));
				});
			} else if (obj.find('input[type="checkbox"], input[type="radio"]').length) {
				obj.off('change.ashVal').on('change.ashVal', function () {
					if (obj.find('input:checked').length >= 1) {
						if (!follower.hasClass(activeValClass)) { //if input doesn't already have validation bound to it
							follower.addClass($$ashVal.valClass);
							$$ashVal.setUpMarkup(follower);
						}
					} else if (follower.hasClass(activeValClass)) { //none checked and follower has validation bound to it
						$$ashVal.unbindValidationFromField(follower);
					}

					$$ashVal.bindValidationToSubmit(follower.closest('form'));
				});
			}
		});
	};

	$$ashVal.bindValidationToSubmit = function (valForm, valInputs) {
		valInputs = valInputs || valForm.find('.' + $$ashVal.valClass).not(exclude);

		valForm.off('submit.ashVal').on('submit.ashVal', function (e) {
			var validationError;
			var	spForm = (valForm.hasClass('spForm')) ? true : false;
			var	spLastPg = $('.spFinal').is(':visible');

			if (!valForm.hasClass('ajaxPost')) {
				if (!spForm || spForm && spLastPg) {
				validationError = $$ashVal.validateAll(valForm, valInputs);

					if (validationError === false) {
						valForm.off('submit.ashVal').submit();
					}
				}
			} else if (valForm.hasClass('ajaxPost') && valForm.hasClass('serverVal')) {
					e.preventDefault();
					$$ashVal.serverVal(valForm);
			}
			e.preventDefault();
		});
		if (Modernizr.input.autofocus) {  //if browser supports autofocus
			$('[autofocus]:not(:focus)').eq(0).focus();  //set autofocus based on property set
		}
	};

	$$ashVal.validateAll = function (valForm, valInputs, opts) { //opts = options to POST with.
		//pass in "undefined" for valInputs to validate all inputs with validate class
		var obj = valForm;
		var	validationError = false; //flag for outside function to indicate a validation error

		$$ash.removeButtonOnSubmit(obj);

		valInputs = valInputs || obj.find('.' + $$ashVal.valClass).not(exclude);

		valInputs.each(function () {
			var obj = $(this);
			if (obj.is(':hidden')) return; //Don't validate fields that are currently hidden
			var	cxt = $$ashVal.preValidate(obj);

			if (obj.is('input:not(.' + exclusiveCheckedClass + '), select, textarea')) {
				$$ashVal.validate(obj, cxt.rules, cxt.errorText, cxt.parent); //normal validation like text fields, etc.
			} else if (obj.find('input[type="checkbox"], input[type="radio"]').length) {
				$$ashVal.validateOnSubmit(obj, cxt.parent, cxt.errorText, cxt.rules); //checkbox/radio containers
			}
		});

		if (obj.find('.' + $$ashVal.failClass + ':visible').length) { //if there's an error in the fields, prevent submission of form
			validationError = true;

			obj.trigger('validationFail');

			if (!obj.parents('.ashModal').length) {
				$$ashVal.scrollForm(obj.find(errorMsgContClass + ':visible').first()); //scroll to first error field
			}
		} else { //if no validation errors
			validationError = false;
		}

		return validationError;
	};

	$$ashVal.scrollForm = function (obj) { //scroll a spot, the obj selector, in the form
		var topOffset;
		var header = $('header');
		var mobileHeader = $('.mobile-header');

		//if to change offset height if it is a mobile view
		if ($(window).width() < 768) {
			topOffset = obj.offset().top - mobileHeader.outerHeight() - 50
		} else {
			topOffset = obj.offset().top - header.outerHeight() - 50
		}

	    if (!obj.isOnScreen() || obj.offset().top > topOffset) {
			$('html, body').stop().animate({
				scrollTop: topOffset
			}, 1000);
		}
	};

	$$ashVal.preValidate = function (obj) { //Grabbing parent, errorText, and rules for performance
		var objParent = obj.parent();
		var	errorMsgCont = objParent.find(errorMsgContClass).last();
		var	rules = {};

		$.each(obj[0].attributes, function () { //grab all attributes of obj. [0] is to grab DOM element, not jQuery element
			if (this.specified && this.name.indexOf(valRulePrefix) !== -1) { //IE8 bug fix and narrow down attributes to validation rules
				rules[this.name.replace(valRulePrefix, '').replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); })] = this.value;
				//strip out the data-attribute prefix and camelCase the rule name eg: data-val-length-min to lengthMin
			}
		});

		//if there aren't any rules yet, add required as the default. this allows you to only add class validate to inputs without custom data attributes or messages
		if (jQuery.isEmptyObject(rules)) {
			rules.required = 'This is required.';
		}
		if (obj.attr('data-val-exclusive-name')) {  //if exclusive, setup reciprocal clearing functions
			$$ashVal.exclusiveRecipricalClearing(obj);
		}
		return { //returns obj's context
			parent: objParent, //object parent
			errorText: errorMsgCont, //container that holds the error message
			rules: rules //validation rules for this particular input
		};
	};

	$$ashVal.exclusiveRecipricalClearing = function (obj) {
		var excObj = obj.find('[data-val-exclusive-item]');  //exclusive input item
		var	valObj = obj.find('.' + $$ashVal.valClass).not(exclude);
		var	popData = obj.hasClass('popData');
		var	allObj = obj.find('input, select, textarea').not('[data-val-exclusive-item], .select2-offscreen, .select2-input');
		var	othObj = obj.find('input[type=checkbox], input[type=radio]').not('[data-val-exclusive-item]');  //other checkbox and radio fields
		var	txtObj = valObj.filter('input').not('[data-val-exclusive-item], [type=checkbox], [type=radio]');  //text fields opposite exclusive
		var	slcObj = valObj.filter('select');
		function stopWatching(obj) {
			//remove validating classes
			if (obj.is('[type=checkbox]') || obj.is('[type=radio]')) {
				obj.removeClass('validate js-watching').addClass(exclusiveCheckedClass);
			} else {
				obj.val('').removeClass('validate js-watching').addClass(exclusiveCheckedClass);
			}

			$$ashVal.clearValidation(obj);
		}

		function startWatching(obj) {
			obj.addClass('validate js-watching').removeClass(exclusiveCheckedClass);
		}
	    // when page loads check for exclusive element (checkbox) value
		if (excObj.is(':checked')) {
		    // clear text, select, and checkboxes, and stop watching them
		    txtObj.each(function () {
		        stopWatching($(this));
                $(this).val('');
            });
		    othObj.each(function () {
		        stopWatching($(this));
                $(this).prop('checked', false).trigger('change');
            });
		    slcObj.each(function () {
		        stopWatching($(this));
                $(this).val('');
            });
		}

		// when exclusive item clicked
		excObj.on('click', function () {
			// if checked after click
		    // clear text, select, and checkboxes, and stop watching them
			if (excObj.is(':checked')) {
			    txtObj.each(function () { stopWatching( $(this) ); $(this).val(''); });
				othObj.each(function () { stopWatching( $(this) ); $(this).prop('checked', false).trigger('change'); });
				slcObj.each(function () { stopWatching( $(this) ); $(this).val(''); });
			// if NOT checked after click
			// start watching all inputs again
			} else {
				txtObj.each(function () { startWatching( $(this) ); });
				othObj.each(function () { startWatching( $(this) ); });
				slcObj.each(function () { startWatching( $(this) ); });
			}
		});

		// when any radio button or checkbox is clicked
		othObj.on('click', function () {
			// clear exclusive item
			excObj.prop('checked', false);
		});

		// when blur event fires on any text input
		txtObj.on('blur', function() {
			// start watching text, select, and checkboxes again
			txtObj.each(function () { startWatching( $(this) ); });
			othObj.each(function () { startWatching( $(this) ); });
			//slcObj.each(function () { startWatching( $(this) ); });

			// clear exclusive item
			excObj.prop('checked', false);
		});
	};

	$$ashVal.equalToFields = function (equalToFld) {
		if (equalToFld && $('[data-val-equalto-other="' + equalToFld + '"]').length > 1) {  //if field matching is active and there are two fields
			//check first and last matching values are equal
			if ($('[data-val-equalto-other="' + equalToFld + '"]').first().val() === $('[data-val-equalto-other="' + equalToFld + '"]').last().val()) {
				return true;
			}
		}
		return false;
	};

	$$ashVal.exclusive = function (exclusiveName) {
		var eParent = $('[data-val-exclusive-name="' + exclusiveName + '"]');  //exclusive items container
		var	eItem = eParent.find('[data-val-exclusive-item]');  //exclusive checkbox
		var	chkbxs = eParent.find('input[type="checkbox"]').not('[data-val-exclusive-item], :disabled');  //other checkboxes in the question
		var	txtflds = eParent.find('input').not('[type=checkbox], [type=radio], :hidden, :disabled');  //other text fields in question
		var	slcts = eParent.find('select').not(':disabled');
		var	isExChkd = false; //is exclusive checkbox checked
		var	isOtherInptUsed = false; //is other textbox or checkboxes used

		if (eItem && eItem.is(':checked')) {  //if exclusive is checked
			isExChkd = true;
		}
		chkbxs.each(function () {
			if ($(this).prop('checked')) {  //if other checkboxes are checked, exclusive is false
				isOtherInptUsed = true;
				return false;
			}
		});
		txtflds.each(function () {  //if other text fields have value, exclusive is false
			if ($(this).val().length > 0) {
				isOtherInptUsed = true;
				return false;
			}
		});
		slcts.each(function () {  //if other select boxes have value, exclusive is false
			if ($(this).val().length > 0) {
				isOtherInptUsed = true;
				return false;
			}
		});
		//if exclusive checked and other inputs used, it is not exclusive
		return isExChkd === isOtherInptUsed ? false : true;  //return result
	};

	$$ashVal.validate = function (obj, rules, errorMsgCont, objParent) {
		//caching these variables for performance
	    var value = $.trim(obj.val()); //user input
		var valueAsInt = parseInt(value, 10); //user input as an integer
		var valueLength = value.length; //user input length
		var required = rules.required;
		var length = rules.length;
		var range = rules.range;
		var equalto = rules.equalto;
		var equaltoOther = rules.equaltoOther;
		var dataTypeFormat = rules.dataTypeFormat;
		var dataTypeFormatMsg = rules.dataType || rules.dataTypeFormatMsg; //AO: rules.dataTypeFormatMsg makes more sense but too late to change markup
		var regex = rules.regex;
		var regexPattern = rules.regexPattern;
		var regexExp;

		hasError = false; //reset hasError flag

		if (obj.hasClass($$ashVal.failClass)) { //reset validation message
			$$ashVal.clearValidation(obj, objParent);
		}
		if (required) { //if input is required
			//strip whitespace so that spaces don't cause this validation to pass
			if (value.length === 0) {
				$$ashVal.markInvalid(obj, required, errorMsgCont, objParent);
			}
		} if (!hasError && length) {
			if (valueLength < rules.lengthMin || valueLength > rules.lengthMax) {
				$$ashVal.markInvalid(obj, length, errorMsgCont, objParent);
			}
		} if (!hasError && range) {
		    if (valueAsInt < rules.rangeMin || valueAsInt > rules.rangeMax) {
				$$ashVal.markInvalid(obj, range, errorMsgCont, objParent);
			}
		} if (!hasError && equalto) {
			//if validating last matching element, check if fields are matching
			if (obj.index() === $('[data-val-equalto-other="' + equaltoOther + '"]').last().index() && !$$ashVal.equalToFields(equaltoOther)) {
				$$ashVal.markInvalid(obj, equalto, errorMsgCont, objParent);
			}
		} if (!hasError && dataTypeFormat) {
			switch (dataTypeFormat) {
				case 'string':
					if (typeof value !== 'string') {
						$$ashVal.markInvalid(obj, dataTypeFormatMsg, errorMsgCont, objParent);
					}
					break;
				case 'number':
					if (isNaN(value) === true) {
						$$ashVal.markInvalid(obj, dataTypeFormatMsg, errorMsgCont, objParent);
					}
					break;
				case 'date':
					if (isNaN(Date.parse(value))) {
						$$ashVal.markInvalid(obj, regex, errorMsgCont, objParent);
					}
					break;
			}
		} if (!hasError && regex) {
			try {
				regexExp = new RegExp(regexPattern);
				if (obj.val() && !regexExp.test(obj.val())) {
					$$ashVal.markInvalid(obj, regex, errorMsgCont, objParent);
				}
			}
			catch (e) {
				console.log('Invalid RegEx pattern. Please contact support.');
				$$ashVal.markInvalid(obj, 'Invalid RegEx pattern. Please contact support.', errorMsgCont, objParent);
			}
		}

		if (hasError === false) {
			$$ashVal.clearValidation(obj, objParent);
		}
	};

	$$ashVal.validateOnSubmit = function (obj, objParent, errorMsgCont, rules) { //for inputs that can only be validated on submit (checkboxes, etc)
		//caching these variables for performance
		var required = rules.required;
		var	exclusiveName = rules.exclusiveName;
		var	equalto = rules.equalto;
        
		hasError = false; //reset hasError flag

		if (objParent.hasClass(parentInvalidClass)) { //reset validation message
		    $$ashVal.clearValidation(obj, objParent);
		}

		if (obj.find('input:checked').length === 0 && !obj.is('[data-val-exclusive-name]')) {
		    $$ashVal.markInvalid(obj, required, errorMsgCont, objParent);
		}

		if (rules.equaltoOther && !$$ashVal.equalToFields(rules.equaltoOther)) {  //if field matching is in use and fields not matching
		    $$ashVal.markInvalid(obj, equalto, errorMsgCont, objParent);
		}

		if (exclusiveName && !$$ashVal.exclusive(exclusiveName)) {
			$$ashVal.markInvalid(obj, obj.find('[data-val-exclusive]').attr('data-val-exclusive'), errorMsgCont, objParent);
		}

		if (hasError === false) {
		    $$ashVal.clearValidation(obj, objParent);
		}
	};

	//function that AJAX POSTs it to the form action
	$$ashVal.serverVal = function (obj) {
	  var dataToSend = $$ash.serializeDataToObject(obj);
		var actionUrl = obj.attr('action');
		var errorMsgCont = $('.serverError');

		$$ash.ajax({
				obj: obj,
				url: actionUrl,
				method: 'POST',
				data: JSON.stringify(dataToSend),
				contentType:"application/json; charset=utf-8",
				dataType:"json",
				failMessageLocation: errorMsgCont,
				failMessagePos: 'prepend',
				failTypeNew: true,
				ajaxIgnoreErrorDisplayCodes: $$ashVal.ajaxIgnoreErrorDisplayCodes,
				error: function (XhrResponse) {
					if (typeof $$ashVal.ajaxCallbacks.error === 'function') $$ashVal.ajaxCallbacks.error(XhrResponse);
					if ($$ashVal.ajaxIgnoreErrorDisplayCodes.indexOf(XhrResponse.status) === -1) {
						$$ash.scroller({
							duration: 1000
						});
					}
				},
				beforeSend: function () {
					if (typeof $$ashVal.ajaxCallbacks.beforeSend === 'function') $$ashVal.ajaxCallbacks.beforeSend();
				}
		});
	};

	$$ashVal.bindValidationClear = function (obj) {
		if (obj.is('input') || obj.is('select') || obj.hasClass('select2-container') || obj.is('textarea')) {

		} else {
			obj.on('change', function () {
				$$ashVal.clearValidation(obj);
			});
		}
	};

	//function that makes hasError true and shows the invalid message
	$$ashVal.markInvalid = function (obj, msg, errorMsgCont, objParent) {
		msg = msg || 'Validation error'; //validation message
		errorMsgCont = errorMsgCont || null; //container that holds the error message
		objParent = objParent || obj.parent(); //object parent

		obj.addClass($$ashVal.failClass);
		objParent.addClass(parentInvalidClass);

		if(objParent.find(errorMsgContClass).length == 0) {
			objParent.append(valMessageCont);
			objParent.find(errorMsgContClass).text(msg);
		}
		
  
		if (!errorMsgCont) {
			obj.after(valMessageCont);
			errorMsgCont = objParent.find(errorMsgContClass);
		}

		errorMsgCont.text(msg);

		if (typeof hasError !== 'undefined') {
			hasError = true;
		}

		$$ashVal.bindValidationClear(obj);
	};

	//function that makes hasError false and hides the invalid message
	$$ashVal.clearValidation = function (obj, objParent) {
		var parentInvalidClass = parentInvalidClass || 'isInvalid';
		var validationText;

		objParent = objParent || obj.parent();

		obj.removeClass($$ashVal.failClass);
		objParent.removeClass(parentInvalidClass);
		
		if(objParent.find(errorMsgContClass.length !== 0 )) {
			objParent.find(errorMsgContClass).remove();
		}
		
		if (obj.hasClass('validate-error')) {
			obj.removeClass('validate-error');
		}

		if (typeof hasError !== 'undefined') {
			hasError = false;
		}
	};

}(window.$$ashVal = window.$$ashVal || {}, jQuery));
